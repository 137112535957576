<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div class="d-flex">
        <h2 class="mb-0 pt-6 pb-6">{{$t('details_laundry.details')}}</h2>
        <div class="input-group input-group-solid m-6 ">
          <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search"></i>
              </span>
          </div>
          <input type="text" class="form-control" v-model="filters.filter" :placeholder="$t('search')" @keydown.enter="doFilter">
        </div>

      </div>
      <div class="d-flex">
        <div class="m-5">
          <span class="d-block text-center">{{$t('orders')}}</span>
          <span class="d-block text-center">{{more_details.total_orders }}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('pieces')}}</span>
          <span class="d-block text-center">{{more_details.items_qty}}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('amount')}}</span>
          <span class="d-block text-center">{{more_details.amount}} {{currency}}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('unpaid')}}</span>
          <span class="d-block text-center">{{more_details.unpaid}} {{currency}}</span>
        </div>
        <div class="m-5 text-center">
          <button  class="btn btn-warning mr-2 ml-2"  @click="doFilter">{{$t('refresh_orders')}}</button>
          <b-button-group class="mr-2 ml-2">
            <b-button @click="ascending = (ascending==1 ? 0 : 1)" variant="primary">
              {{$t('arrange_orders')}}
              <i class="fa fa-arrow-down" v-if="ascending==1"></i>
              <i class="fa fa-arrow-up" v-else></i>
            </b-button>
            <b-dropdown :text="orderByLabel" variant="primary">
              <b-dropdown-item href="javascript:;" v-for="(or, index) in order_by_list" :key="'or-'+index" @click="getOrderBy(or.id)">{{or.label}}</b-dropdown-item>
            </b-dropdown>
          </b-button-group>

        </div>
      </div>
    </div>
    <div class="col-md-12">
      <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
        <template slot="order" slot-scope="props">
          <span v-for="(row, index) in props.row.items_list" v-if="row.item && row.item.name" class="d-block">{{ row.item.name }} <i class="fas fa-times" style="font-size: 10px;color: #3F4254;"></i> {{ row.qty }}</span>
        </template>
        <template slot="actions" slot-scope="props">
          <b-button size="sm" @click="showDetailsModal(props.row)">{{ $t('details_laundry.details') }}</b-button>
        </template>
      </v-server-table>
      <b-modal ref="orderDetailsModal" size="md" hide-footer :title="$t('details_laundry.order_details')">
        <div class="row">
          <div class="col-md-6 pt-3">
            <p><b>{{$t('details_laundry.construction')}}</b></p>
            <p v-if="order_details.invoice_code">#{{order_details.invoice_code}}</p>
            <p v-if="order_details.delivery_date">{{order_details.delivery_date}}</p>
            <p v-if="order_details.delivery_time">{{order_details.delivery_time}}</p>
            <p v-if="order_details.customer && order_details.customer.name">{{order_details.customer.name}}</p>
            <p v-if="order_details.customer && order_details.customer.address_1">{{order_details.customer.address_1}}</p>
            <p></p>
          </div>
          <div class="col-md-6 pt-3">
            <p><b>{{$t('details_laundry.payment')}}</b></p>
            <p v-if="order_details.is_paid_status_name">
              <span v-if="order_details.is_paid_status_name"> {{order_details.is_paid_status_name}}: </span>
              <span v-if="order_details.payment_amount"> {{order_details.payment_amount}} </span>
              <span v-if="order_details.currency && order_details.currency.code"> {{order_details.currency.code}} </span></p>
          </div>
          <div class="col-md-12 pt-3">
            <p><b>{{$t('details_laundry.cleaning')}}</b></p>
            <table class="table">
              <thead>
              <tr>
                <th>{{$t('details_laundry.item')}}</th>
                <th>{{$t('details_laundry.note_or_problem')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in order_details.items_list" :key="'items_list'+index">
                <td>{{row.item.name}}</td>
                <td>
                  <span v-for="(damage, damage_index) in row.damages_list" :key="'damage_index'+damage_index">{{damage.name}} / </span>
                  <span v-for="(stain, stain_index) in row.stains_list" :key="'stain_index'+stain_index">{{stain.name}} / </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Details",
  props:{
    currency: {type: String},
    sessionId: null,
  },
  data() {
    return {
      mainRoute: 'sales/sales_laundry/session',
      data: [],
      columns: ['id', 'invoice_code', 'invoice_date', 'customer_name', 'order', 'invoice_total', 'actions'],
      invoice_id: null,
      rack: null,
      validation: null,
      filters:{
        filter:''
      },
      more_details: {
        total_orders: 0,
        items_qty: 0,
        amount: 0,
        unpaid: 0,
      },
      order_by_list:[
        {id: 'id', label:this.$t('details_laundry.id')},
        {id: 'invoice_code', label:this.$t('details_laundry.invoice_code')},
        {id: 'invoice_date', label:this.$t('details_laundry.invoice_date')},
        {id: 'invoice_total', label:this.$t('details_laundry.total')},
      ],
      ascending: 1,
      orderBy:'id',
      order_details: {},

    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('details_laundry.id'),
          invoice_code: that.$t('details_laundry.invoice_code'),
          invoice_date: that.$t('details_laundry.invoice_date'),
          customer_name: that.$t('details_laundry.customer'),
          order: that.$t('details_laundry.order'),
          pcs: that.$t('details_laundry.Pcs'),
          notes: that.$t('details_laundry.notes'),
          invoice_total: that.$t('details_laundry.total'),
          actions: '',
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [],

        requestFunction(data) {
          let _params = {
            ascending: that.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: that.orderBy,
            page: data.page,
            // filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}/${that.sessionId}`, {..._params});

        },
        responseAdapter(resp) {
          that.more_details.total_orders = resp.data.data.more_details.total_orders;
          that.more_details.items_qty = resp.data.data.more_details.items_qty;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
    orderByLabel(){
      let _label = this.order_by_list.find(row=>row.id == this.orderBy);
      return _label? _label.label : '';

    }
  },
  watch:{
    ascending: function (val){
      this.doFilter();
    }
  },
  methods: {
    showDetailsModal(row) {
      this.order_details = row;
      this.$refs['orderDetailsModal'].show();
    },
    hideDetailsModal() {
      this.order_details = null;
      this.$refs['orderDetailsModal'].hide();
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    getOrderBy(id){
      this.orderBy = id;
    },
  }
}
</script>

<style scoped>

</style>