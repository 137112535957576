<template>
  <div>
    <!--begin::customer-->
    <div class="row">
      <div class="col-md-8 pt-3">
        <div class="form-group">
          <div class="row">
            <div class="col-md-3">
              <label>{{ $t('payment_method') }}<span class="text-danger">*</span></label>
              <!-- <p class="badge badge-dark">{{payment_method}}</p> -->
              <select id="status" class="custom-select" v-model="is_cash" :class="validation && validation.payment_method ? 'is-invalid' : ''">
                <option :value="row.id" v-for="(row , index) in payments_methods" :key="index">
                  {{ row.title }}
                </option>
              </select>
              <span v-if="validation && validation.payment_method[0]" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_method[0] }}
                            </span>
            </div>
            <div class="col-md-9 pt-10">
              <p>{{ $t('treasury') }}: {{ (is_cash == 1 && treasury_cash) ? treasury_cash.name : '' }} {{ (is_cash == 3 && treasury_card) ? treasury_card.name : '' }}</p>
            </div>

            <!--            <div class="col-md-3">-->
            <!--              <label>{{ $t('payment_method') }}</label>-->
            <!--              <select id="status" class="custom-select" v-model="is_cash">-->
            <!--                <option :value="row.id" v-for="(row , index) in payments_methods" :key="index">-->
            <!--                  {{ row.name }}-->
            <!--                </option>-->
            <!--              </select>-->
            <!--            </div>-->
            <!--            -->
            <!--            -->
            <!--            <div class="col-md-3 mt-8">-->
            <!--              <label></label>-->
            <!--              <select name="" id="status" class="custom-select" v-model="method2">-->
            <!--                <option value=""></option>-->
            <!--                <option :value="row.id" v-for="(row,index) in payments_methods" :key="index">-->
            <!--                  {{ row.name }}-->
            <!--                </option>-->
            <!--              </select>-->
            <!--            </div>-->
          </div>
          <div class="row">
            <div class="col-lg-6" v-if="accounting_System_per_invoice">
              <label>{{ $t('accounts') }}</label>
              <treeselect
                  :options="accounts"
                  :load-options="loadOptions"
                  :multiple="false"
                  :value="account_id"
                  @input="updateValue($event)"
                  :searchable="true"
                  :disabled="isDisabled"
                  :show-count="true"
                  :no-children-text="$t('No_sub_options')"
                  :no-options-text="$t('No_options_available')"
                  :no-results-text="$t('No_results_found')"
                  :placeholder="$t('Select')">
              </treeselect>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t('payment_amount') }}</label>
          <input type="number" min="0" class="form-control" v-model="payment_amount"/>
        </div>
        <div class="buttons-2">
          <div class="button button-st-2" @click="selectNumber('1')">1</div>
          <div class="button button-st-2" @click="selectNumber('2')">2</div>
          <div class="button button-st-2" @click="selectNumber('3')">3</div>
          <div class="button button-st-2" @click="selectNumber('4')">4</div>
          <div class="button button-st-2" @click="selectNumber('5')">5</div>
          <div class="button button-st-2" @click="selectNumber('6')">6</div>
          <div class="button button-st-2" @click="selectNumber('7')">7</div>
          <div class="button button-st-2" @click="selectNumber('8')">8</div>
          <div class="button button-st-2" @click="selectNumber('9')">9</div>
          <div class="button button-st-2" @click="selectNumber('0')">0</div>
          <div class="button button-st-2" @click="selectNumber('.')">.</div>
          <div class="button button-st-2" @click="restCalc">←</div>
        </div>
      </div>
      <div class="col-md-4 pt-3">
        <div class="border-2 bg-f9fbfc">
          <div class="d-flex justify-content-between p-4 bg-ebf1f3">
            <div>
              <h5>{{ $t('client') }}</h5>
              <p class="mb-0">{{ customer ? customer.fullname : '' }}</p>
              <p>{{ customer ? customer.email : '' }}</p>
            </div>
            <div>
              <b-icon icon="person-plus-fill" font-scale="3"></b-icon>
            </div>
          </div>
          <div class="p-4">
            <!--                        <div class="form-group mb-0">-->
            <!--                            <label>Payment method</label>-->
            <!--                            <div>-->
            <!--                                <p class="badge badge-dark">{{payment_method}}</p>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <div class="form-group mb-0">
              <label>{{ $t('payment_list') }}</label>
              <div class="input-group">
                <!--                                <input type="text" class="form-control" :value="payment_method" readonly>-->
                <input type="text" class="form-control" :value="method1_name" readonly>
                <input type="number" min="0" class="form-control" v-model="payment_amount">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ currencyName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="p-4">
            <div class="form-group">
              <label>{{ $t('point_of_sales.refund_discount') }}</label>
              <div class="input-group">
                <input type="number" min="0" class="form-control" disabled v-model="total_dis"/>
                <select v-model="discount_type" disabled class="custom-select">
                  <option value="percentage">%</option>
                  <option value="value">{{ currencyName }}</option>
                </select>
              </div>

            </div>
          </div>
        </div>
        <div class="border-2 bg-f9fbfc mt-5">
          <div class="p-3">
            <p><b>{{ $t('point_of_sales.previous_invoice_total') }}</b></p>
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.subTotal') }}: </span><span>{{ previousInvoiceTotal }} {{ currencyName }}</span></p>
          </div>
          <hr style="height:2px;border-top:2px dashed #bdc3c7; color:#fff; background-color:#fff">
          <div class="p-3">
            <p><b>{{ $t('order_details') }}</b></p>
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.subTotal') }}: </span><span>{{ total_items }} {{ currencyName }}</span></p>
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.refund_discount') }}: </span><span>{{ total_dis }} {{ currencyName }}</span></p>
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.total') }}: </span><span>{{ total_final }} {{ currencyName }}</span></p>
          </div>
          <hr style="height:2px;border-top:2px dashed #bdc3c7; color:#fff; background-color:#fff">
          <div class="p-3">
            <p><b>{{ $t('point_of_sales.tax') }}</b></p>
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.zero') }}: </span><span>0.00 {{ currencyName }}</span></p>
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.recovery') }}: </span><span>0.00 {{ currencyName }}</span></p>
          </div>
          <hr style="height:2px;border-top:2px dashed #bdc3c7; color:#fff; background-color:#fff">
          <div class="p-3">
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.net_payable') }}: </span><span>{{ total_final }} {{ currencyName }}</span></p>
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.paid') }}: </span><span>{{ payment_amount }} {{ currencyName }}</span></p>
            <p class="mb-0 d-flex justify-content-between" v-if="method2_name"><span>{{ method2_name }}: </span><span>{{ method2_amount }} {{ currencyName }}</span></p>
            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.change') }}: </span><span>0.00 {{ currencyName }}</span></p>
          </div>
        </div>
        <div class="mt-5">
          <div class="btn-group w-100" role="group">
            <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
            <button type="reset" class="btn btn-success" :disabled="send_request_disabled" @click="save">{{ $t('confirm') }}</button>
          </div>
        </div>
      </div>
    </div>

    <!--end::customer-->
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-pos-payment",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    hideSaveModal: {type: Function},
    resetList: {type: Function},
    items: null,
    totalItems: null,
    totalDis: null,
    shippingVal: null,
    customer: null,
    currencyId: null,
    currencyName: null,
    totalTax: null,
    previousInvoiceTotal: null,
    invoiceId: null,
    totalFinal: 0,
  },
  data() {
    return {
      mainRoute: '/pos/point-sale/refund/items',
      mainRouteDependency: 'base/dependency',

      validation: null,
      customer_id: null,
      num_calc: '',
      total_items: '',
      total_dis: '',
      total_final: '',
      shipping_val: '',
      payment_amount: '',
      discount_type: 'value',
      is_cash: '',
      method1_name: '',
      method2: '',
      method2_name: '',
      method2_amount: '',
      payment_method_id1: '',
      payment_method_id2: '',
      total_payment_amount: '',
      payment_method: 'Cash Refund',
      payments_methods: [],
      items_list: [],
      accounting_System_per_invoice: '',
      isDisabled: false,
      account_id: '',
      accounts: [],
      treasury_cash: null,
      treasury_card: null,
      treasury_cash_2: null,
      treasury_card_2: null,
      treasury_id: null,
      send_request_disabled: true,
    };
  },
  watch: {
    items: function (val) {
      this.items_list = val;
    },
    totalItems: function (val) {
      this.total_items = val;
    },
    totalDis: function (val) {
      this.total_dis = val;
    },
    is_cash: function (val) {
      if (val) {
        let _method1_name = this.payments_methods.find(x => x.id == val);
        if (_method1_name) {
          this.method1_name = _method1_name.title
          this.payment_method_id1 = val
        }
        this.getDefaultTreasury(val, 1);
      } else {
        this.method1_name = '';
      }
    },
    method2: function (val) {
      if (val) {
        this.method2_name = this.payments_methods.filter(x => x.id == val);
        if (this.method2_name[0]) {
          this.method2_name = this.method2_name[0].name
          this.payment_method_id2 = val
        }
      } else {
        this.method2_name = '';
      }
    },
    payment_amount: function (val) {
      let _amount = parseFloat(val);
      _amount = Math.abs( _amount )
      this.payment_amount = _amount;

    },
  },
  methods: {
    save() {
      this.create();
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
      this.finishedSave();
      this.getPosSetting();
      this.getDefaultAccount();
    },
    closeAndSave() {
      this.$emit('hide-save-modal');
      this.resetAfterSave();
      this.finishedSave();
      this.getPosSetting();
      this.getDefaultAccount();
    },
    callPrintInvoice() {
      // setTimeout(() => {
      //   document.getElementById("printInvoice").click();
      // }, 5000);
      window.open('/print/point-sale-invoice-refund', "_blank");

    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    create() {
      let payment_amount_details = [
        {
          payment_method_id: this.payment_method_id1,
          type: this.method1_name,
          amount: this.payment_amount,
        }
      ];
      if (this.method2_amount) {
        payment_amount_details.push({
          payment_method_id: this.payment_method_id2,
          type: this.method2_name,
          amount: this.method2_amount,
        });
        if (this.method2_name) {
          this.total_payment_amount = (parseFloat(this.payment_amount) + parseFloat(this.method2_amount));
        }
      }

      let _items_list = this.items_list.filter(row => row.is_return);
      ApiService.post(this.mainRoute, {
        items_list: _items_list,
        invoice_subtotal: this.total_items,
        invoice_total_shipping: this.shipping_val,
        total_dis: this.total_dis,
        total_tax: this.totalTax,
        total_final: this.total_final,
        customer_id: this.customer_id,
        currency_id: this.currencyId,
        invoice_id: this.invoiceId,
        payment_amount: this.payment_amount,
        payment_amount_details: payment_amount_details,
        payment_method: this.payment_method_id1,
        total_payment_amount: this.total_payment_amount,
        account_id: this.account_id,
        treasury_id: this.treasury_id,

      }).then((response) => {
        this.$successAlert(response.data.message);
        Vue.prototype.$postStatus = true;
        this.closeAndSave();
        this.$emit('reset-list');
        this.callPrintInvoice();
        // document.getElementById('invoice').contentWindow.location.reload()
      }).catch((error) => {
        this.$errorAlert(error);
        Vue.prototype.$postStatus = true;
      });
    },
    resetAfterSave() {
    },

    async getPosSetting() {
      await ApiService.get(`${this.mainRouteDependency}/pos_setting`).then((response) => {
        this.accounting_System_per_invoice = response.data.data.accounting_System_per_invoice;
      });
    },

    loadOptions() {
    },

    getAccounts() {
      ApiService.get(this.mainRouteDependency + "/account_parents").then((response) => {
        this.accounts = response.data.data;
      });
    },

    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/returns`).then((response) => {
        if (this.accounting_System_per_invoice === true) {
          this.account_id = null;
          this.isDisabled = false;
        } else if (this.accounting_System_per_invoice === false) {
          this.account_id = response.data.data.id;
          this.isDisabled = true;
        }
      });
    },
    updateValue(value) {
      this.account_id = value;
    },

    selectNumber(num) {
      this.num_calc += num;
      this.payment_amount = this.num_calc;
      this.method2_amount = (this.total_final - this.payment_amount).toFixed(2);
      // this.total_items = this.num_calc;
      // this.total_final = ((this.total_items ? parseFloat(this.total_items) : 0) - (this.total_dis? parseFloat(this.total_dis) : 0) + (this.shipping_val? parseFloat(this.shipping_val) : 0) + (this.totalTax? parseFloat(this.totalTax) : 0)).toFixed(2);
    },
    restCalc() {
      this.num_calc = '';
    },
    getPaymentsMethods() {
      ApiService.get(`${this.mainRouteDependency}/payment_method`).then((response) => {
        this.payments_methods = response.data.data;
        // response.data.data.forEach(element => {
        //   if (element.cash == 1) {
        //     this.is_cash = element.id;
        //     this.method1_name = element.name;
        //   }
        //   this.method2 = '';
        // });
      });
    },
    getDefaultTreasury(id, _for) {
      if (id)
        ApiService.get(`${this.mainRouteDependency}/pos_default_treasury/${id}`).then((response) => {

          if (_for == 1) {
            switch (id) {
              case 1:
                this.treasury_cash = response.data.data;
                this.treasury_id = response.data.data.id;
                break;

              case 3:
                this.treasury_card = response.data.data;
                this.treasury_id = response.data.data.id;
                break;

            }
          }
          if (_for == 2) {
            switch (id) {
              case 1:
                this.treasury_cash_2 = response.data.data;
                break;

              case 3:
                this.treasury_card_2 = response.data.data;
                break;

            }
          }
          this.send_request_disabled = false;
        });
    },
    async getDefaultPaymentMethod() {
      await ApiService.get(`${this.mainRouteDependency}/pos_default_payment_method`).then((response) => {
        this.is_cash = response.data.data.id;
        this.payment_method_id1 = response.data.data.id;
        this.method1_name = response.data.data.title;
        this.method2 = '';
      });
    },
  },

  mounted() {
    this.getPaymentsMethods();
    let promise = this.getPosSetting();
    let promise2 = this.getDefaultPaymentMethod();
    Promise.all([promise, promise2]).then(() => {
      this.getAccounts();
      this.getDefaultAccount();
    });
    this.getPaymentsMethods();
    this.total_items = this.totalItems ? this.totalItems : 0;
    this.total_dis = this.totalDis ? this.totalDis : 0;
    this.shipping_val = this.shippingVal ? this.shippingVal : 0;
    this.total_final = this.totalFinal? this.totalFinal : 0;//((this.totalItems ? parseFloat(this.totalItems) : 0) - (this.totalDis ? parseFloat(this.totalDis) : 0) + (this.shippingVal ? parseFloat(this.shippingVal) : 0) + (this.totalTax ? parseFloat(this.totalTax) : 0)).toFixed(2);
    this.items_list = this.items;
    this.customer_id = this.customer.id;
    this.payment_amount = this.total_final;


  },
};
</script>

<style>
.bg-ebf1f3 {
  background-color: #ebf1f3;
}

.bg-f9fbfc {
  background-color: #f9fbfc;
}

.border-2 {
  border-radius: 5px;
  border: 2px solid #babde2;
}
</style>