<template>
  <div :key="'screen'+indexScreen">
    <div v-show="mainScreen == 'new_order'">
      <div class="row pl-5 pr-5 pb-0 pt-0">
        <div class="col-md-6 pl-0 pr-0 border-1-EBEDF3-without-top">

          <div class="d-flex justify-content-between col-md-12 pl-3 pr-3 pt-4 pb-4">
            <div class="d-flex my-auto">
              <span @click="getClient"><i class="fa fa-user-circle pl-0" style="font-size: 26px;"></i></span>
              <span @click="getClient" class="ml-3 mr-3 pt-1 cursor-pointer">{{ client_name }}</span>
            </div>
            <div class="d-flex">
              <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('discount')" :disabled="(selected_items.length < 1)" @click="addAction('dis_per')">
                <i class="flaticon2-percentage text-0C6F58 pl-0"></i>
              </button>
              <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('shipping')" :disabled="(selected_items.length < 1)" @click="addAction('shipping')">
                <i class="flaticon2-delivery-truck text-0C6F58 pl-0"></i>
              </button>
              <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('delivery_and_received')" :disabled="(selected_items.length < 1)" @click="showChangeDeliveryReceivedModal">
                <i class="fas fa-calendar-check text-0C6F58 pl-0"></i>
              </button>
<!--              <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('pricing_information')" :disabled="(selected_items.length < 1)" @click="showPricingInformationModal">-->
<!--                <i class="fas fa-cash-register text-0C6F58 pl-0"></i>-->
<!--              </button>-->
              <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('notes')" @click="showNotesCartModal">
                <i class="flaticon-notepad text-0C6F58 pl-0"></i>
              </button>
              <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('remove_all')" @click="removeAllSelectedItem">
                <i class="flaticon-delete text-danger pl-0"></i>
              </button>
            </div>
          </div>

<!--          <div class="form-group mb-4 mr-3 ml-3">-->
<!--            <div class="input-group input-group-solid">-->
<!--              <div class="input-group-prepend">-->
<!--              <span class="input-group-text">-->
<!--                <i class="fa fa-barcode"></i>-->
<!--              </span>-->
<!--              </div>-->
<!--              <input type="text" class="form-control" :id="'barcodeInput'+indexScreen" :key="'barcodeInput'+indexScreen" :ref="'barcodeInput'+indexScreen" placeholder="ادخل الباركود" v-model="data.barcode" autocomplete="off">-->
<!--              <div class="input-group-append">-->
<!--                <button @click="searchOnEnterInput" class="btn btn-primary"><i class="fa fa-search p-0"></i></button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->


          <div class="col-md-12 pl-0 pr-0">
            <div style="height: 487px;">
              <div style="overflow-y: scroll;height: 310px;">
                <div class="d-flex border-bottom-1-EBEDF3" v-for="(row, index) in selected_items" :key="index" :style="{'background-color': (index==index_item_selected? '#fff6d4': (row.is_return? '#f2f2f2':'#fff')), 'height': 'auto'}">
                  <div class="border-with-space" style="margin: 0.75rem 0.5rem;">
                    <div class="d-block text-center">{{ row.insert_time }}</div>
                    <div class="d-block text-center mt-3"><i class="flaticon2-plus text-dark"></i></div>
                  </div>
                  <div class="d-flex justify-content-between w-100">
                    <div style="margin: 0.75rem 0.5rem;">
                      <div class="d-block">
                        <h6>
                          <a href="javascript:;" class="d-inline-block mr-1 ml-1" @click="showMoreDetailsForItem(index)" v-b-tooltip.hover :title="$t('quantity_details')"> <i class="fa fa-edit text-FFB803"></i> </a>
                          <a href="javascript:;" @click="indexItemSelected(index)"><span v-if="row.symbol">({{ row.symbol }})</span> <span>{{ row.name }}</span></a>
                        </h6>

                      </div>
                      <div class="d-flex">
                        <span style="padding-top: 7px;min-width: 60px;">{{ row.unit_price }}</span>
                        <div class="d-flex mr-2 ml-2">
                          <i class="fa fa-arrow-down text-0C6F58" style="padding: 10px 10px 0;"></i>
                          <div class="input-group">
                            <input type="text" class="form-control form-control-sm" v-model="row.discount_val" @input="setDiscountValPer($event, index)" placeholder="00.00" style="width: 80px;">
                            <div class="input-group-append" style="margin-left: -2px">
                              <button class="btn btn-sm 2" @click="setDiscountType(index, 2)" :class="{'btn-primary' :row.discount_type == 2, 'btn-outline-primary':row.discount_type != 2}"><i class="fa fa-percent" style="font-size: 12px;padding-left:unset;"></i></button>
                              <button class="btn btn-sm 3" @click="setDiscountType(index, 3)" :class="{'btn-primary' :row.discount_type == 3, 'btn-outline-primary':row.discount_type != 3}">{{ currency_code }}</button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <template v-if="row.attached_reasons[0]">
                          <template v-for="(attached_reasons_main, _index) in row.attached_reasons[0]">
                            <span v-for="(attached_reasons_row, index) in attached_reasons_main.attached_reasons" :key="'attached_reasons_row'+index+'_attached_reasons_main'+_index">{{ attached_reasons_row.name }}<span v-if="index < attached_reasons_main.attached_reasons.length && attached_reasons_row"> / </span></span>
                          </template>
                        </template>

                        <template v-if="row.colors[0]">
                          <span v-for="(color_row, index) in row.colors[0]" :key="'color_row'+index">{{ getColorName(color_row) }}<span v-if="index < row.colors[0].length && color_row"> / </span></span>
                        </template>
                      </div>


                      <!--                      <span v-for="(attached_reasons_row, index) in row.attached_reasons" :key="'attached_reasons_row'+index">{{ getAttachedReasonsName(attached_reasons_row) }}<span v-if="index < row.attached_reasons.length && attached_reasons_row"> / </span></span>-->

                      <!--                      <div v-for="(qty_row, qty_index) in row.qty">-->
                      <!--                        <template v-if="row.attached_reasons[qty_index]">-->
                      <!--                          <span v-for="(attached_reasons_row, index) in row.attached_reasons[qty_index]" :key="'attached_reasons_row'+index">{{ getAttachedReasonsName(attached_reasons_row) }}<span v-if="index < row.attached_reasons[qty_index].length && attached_reasons_row"> / </span></span>-->
                      <!--                        </template>-->

                      <!--                        <template v-if="row.colors[qty_index]">-->
                      <!--                          <span v-for="(color_row, index) in row.colors[qty_index]" :key="'color_row'+index">{{ getColorName(color_row) }}<span v-if="index < row.colors[qty_index].length && color_row"> / </span></span>-->
                      <!--                        </template>-->


                      <!--&lt;!&ndash;                        <template v-if="row.stains[qty_index]">&ndash;&gt;-->
                      <!--&lt;!&ndash;                          <span v-for="(stains_row, index) in row.stains[qty_index]" :key="'stains_row'+index">{{ getStainsName(stains_row) }}<span v-if="index < row.stains[qty_index].length && stains_row"> / </span></span>&ndash;&gt;-->
                      <!--&lt;!&ndash;                        </template>&ndash;&gt;-->
                      <!--&lt;!&ndash;                        <template v-if="row.bubbles[qty_index]">&ndash;&gt;-->
                      <!--&lt;!&ndash;                          <span v-for="(bubbles_row, index) in row.bubbles[qty_index]" :key="'bubbles_row'+index">{{ getBubblesName(bubbles_row) }}<span v-if="index < row.bubbles[qty_index].length && bubbles_row"> / </span></span>&ndash;&gt;-->
                      <!--&lt;!&ndash;                        </template>&ndash;&gt;-->
                      <!--                        <div v-if="showBarCart(index, qty_index)" style="border-bottom: 2px dashed #c0c0c0;padding-top: 5px;padding-bottom: 5px;"></div>-->
                      <!--                      </div>-->
                    </div>
                    <div class="d-flex" style="margin: 0.5rem 0.5rem;">
                      <div style="margin: 0 0.75rem 0.5rem;">
                        <div class="d-block text-center">
                          <b-form-spinbutton :value="row.qty" @change="changeQty(index, $event)" :min="1" :step="1" class="custom-spinbutton"></b-form-spinbutton>
                          <!--                          <b-form-spinbutton v-else v-model="row.qty" @change="changeQty(index, $event)" :min="1" :max="row.available_stock_qty" :step="1" class="custom-spinbutton"></b-form-spinbutton>-->
                        </div>
                        <div class="d-block text-center" style="padding-top: 2px;">
                          <b class="text-FFB803" style="font-size: 16px;">{{ row.subtotal }}</b>
                        </div>
                      </div>
                      <div style="margin: 0.75rem 0.5rem;">
                        <a v-if="is_refund_available && !row.is_return" href="javascript:;" @click="cloneSelectedItem(index)" v-b-tooltip.hover.top :title="$t('point_of_sales.return')"><i class="fas fa-angle-down text-warning font-size-20px"></i></a>
                        <a v-else-if="is_refund_available && row.is_return" href="javascript:;" @click="removeItem(index)" v-b-tooltip.hover.top :title="$t('point_of_sales.remove')"><i class="flaticon-delete text-danger"></i></a>
                        <a v-else-if="!is_refund_available && !row.is_return" href="javascript:;" @click="removeItem(index)" v-b-tooltip.hover.top :title="$t('point_of_sales.remove')"><i class="flaticon-delete text-danger"></i></a>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div class="col-md-12 pb-5 pt-5 pl-0 pr-0 border-top-1-solid">
                <div class="row pb-4">
                  <div class="text-center border-with-space col-md-3">
                    <h6>{{ is_refund_available ? $t('point_of_sales.total_current_bill_amount') : $t('point_of_sales.subTotal') }}</h6>
                    <p class="mb-2">{{ (is_refund_available ? invoice_subtotal : total_items) }} {{ currency }}</p>
                  </div>
                  <div class="text-center border-with-space col-md-3">
                    <h6>{{ is_refund_available ? $t('point_of_sales.refund_discount') : $t('point_of_sales.discount') }}</h6>
                    <p class="mb-2">{{ total_dis ? total_dis : '0' }} {{ currency }}</p>
                  </div>
                  <div class="text-center border-with-space col-md-3">
                    <h6>{{ $t('point_of_sales.shipping') }}</h6>
                    <p class="mb-2">{{ shipping_val ? shipping_val : '0' }} {{ currency }}</p>
                  </div>
                  <div v-if="is_apply_taxes_invoice" class="text-center col-md-3">
                    <h6>{{ $t('point_of_sales.tax') }}</h6>
                    <p class="mb-2">{{ total_tax ? total_tax : '0' }} {{ currency }}</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="pt-4 border-top-2-dotted">
                    <h5 class="mb-2">{{ is_refund_available ? $t('point_of_sales.refund_rotal') : $t('point_of_sales.total') }} {{ total_final }} {{ currency }}</h5>
                  </div>
                  <div class="mt-4" v-if="is_refund_available">
                    <h5 class="mb-2">{{ is_refund_available ? $t('point_of_sales.total_amount_before_return') : $t('point_of_sales.total') }} {{ invoice_total }} {{ currency }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-12 mb-5">
            <b-button variant="primary" class="w-100" :disabled="!activePaymentBtn" @click="(is_refund_available ? showRefundModal() : showPaymentModal())">{{ $t('payment') }}</b-button>
          </div>
        </div>
        <div class="col-md-6 pl-0 pr-0">
          <div class="card-title mb-1">
            <div class="form-group ml-0 mr-0 mb-0">
              <div class="d-flex mt-4">
                <div class="d-block w-100">
                  <button v-for="(row , index) in searchBox" :key="index" class="btn mr-2 ml-2" :class="data.type == row.id ? 'btn-primary' :'btn-light'" @click="data.type = row.id">{{ row.name }}</button>
                </div>

                <div class="input-group input-group-solid">
                  <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search"></i>
              </span>
                  </div>
                  <input type="text" class="form-control" v-model="data.name" :placeholder="$t('search')" @keydown.enter="searchOnEnter">
                </div>

              </div>
              <div class="mt-4 d-flex justify-content-between">
                <div>
<!--                  <label class="label label-light label-inline m-2 p-5 w-max-content cursor-pointer" @click="getItems">الكل</label>-->
                  <label class="label label-light label-inline m-2 p-5 w-max-content cursor-pointer" v-for="row in laundry_services[0]" @click="chooseLaundryServices(row)" :key="row.id">{{ row.name }}</label>
                </div>
                <b-dropdown v-if="laundry_services[1] && laundry_services[1].length > 0" id="dropdown-categories" variant="light" :text="' '" ref="dropdown" menu-class="dropdown-categories-list" class="m-2">
                  <label class="label label-light label-inline mr-2 ml-2 mb-2 mt-2 w-max-content cursor-pointer" v-for="row in laundry_services[1]" @click="chooseLaundryServices(row)" :key="row.id">{{ row.name }}</label>
                </b-dropdown>
              </div>
            </div>
          </div>

          <div class="d-flex position-relative">
            <div class="card-body" style="height: 558px;overflow-y: scroll;width: 843px;padding: 1rem;">
              <div v-if="show_loader" class="form-group row text-center justify-content-center" style="width: 100%;height: 100%;">

                <div>
                  <i class="fas fa-sync fa-spin" style="font-size: 75px"></i>
                </div>

              </div>

              <div v-if="!show_loader" class="form-group row">
                <div class="col-12 d-flex justify-content-between">
                  <div>
                    <button class="btn btn-primary btn-sm" v-if="pagination.current_page > 1" @click="previousPage">{{ $t('previous') }}</button>
                  </div>
                  <div>
                    <button class="btn btn-primary btn-sm" v-if="pagination.last_page != pagination.current_page" @click="nextPage">{{ $t('next') }}</button>
                  </div>

                </div>

                <template>
                  <template v-if="isClient == true">

                    <div class="col-md-4 pt-3 cursor-pointer">
                      <div class="card card-custom card-fit card-border cursor h-100" @click="showModal" style="align-items: center;justify-content: center;">
                        <div class="card-header p-2">
                          <div class="card-title m-0" @click="showModal">
                            <h3 class="card-label m-0">
                              {{ $t('add_client') }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 pt-3 cursor-pointer" v-for="row in customers" :key="row.id" @click="switchOnProducts(row.name, row)">
                      <div class="card card-custom card-fit card-border cursor f-s-14" :class="{'default-client': (row.type == 1) }">
                        <div class="card-header p-2">
                          <div class="card-title m-0">
                            <h5 class="card-label m-0" style="font-size: 1.1rem;">
                              {{ row.name ? row.name : '' }}
                            </h5>
                          </div>
                        </div>
                        <div class="card-body p-2">
                          <span class="d-block d-flex mb-2"><i class="fa fa-phone-alt"></i> <span class="d-block hidden-line" style="margin: 0 5px;direction: ltr;">{{ row.phone }}</span></span>
                          <span class="d-block d-flex"><i class="fa fa-envelope"></i> <span class="d-block hidden-line" style="margin: 0 5px;direction: ltr;">{{ row.email }}</span></span>
                        </div>

                      </div>
                    </div>
                  </template>


                  <template v-if="isClient == false && data.type == 1">
                    <div class="item" v-for="row in items" :key="'a'+row.id" @click="(show_product_info? selectToShowProductInfo(row.id) : selectItem(row))">
                      <div>
                        <div class="p-2">
                          <a href="javascript:;" class="text-0C6F58" style="margin: 0 5px;" @click.stop="showItemsGroupModal(row)" v-if="row.item_type != 1"><i class="fa fa-table"></i></a>
                          <span>{{ row.name }}</span>
                        </div>
                        <img :src="row.image_url" class="img-fluid" alt="Image">
                      </div>
                    </div>
                    <a href="javascript:;" class="item item-actions-style" @click="showNewItemModal">
                      <div class="d-flex text-center justify-content-center align-items-center flex-column">
                        <i class="fa fa-plus-circle font-size-18"></i>
                        <div class="p-2">{{ $t('product') }}</div>
                      </div>
                    </a>

                  </template>

                  <template v-if="isClient == false && data.type == 2">
                    <div class="col-md-3 pt-3" v-for="row in invoices" :key="'b'+row.id">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title">#{{ row.invoice_code }}</h5>
                          <h6 class="card-subtitle mb-2">{{ row.customer_name }}</h6>
                          <p class="card-text">{{ row.invoice_total }} {{ currency }}</p>
                        </div>
                        <a href="javascript:;" @click="makeRefundAvailable(row.id)" class="btn btn-danger col-12 rounded-0">{{ $t('MENU.refund_invoice') }}</a>
                      </div>
                    </div>
                  </template>

                </template>
              </div>

            </div>
            <div class="mr-1 ml-1" style="width: 253px;">
              <div class="accordion border" role="tablist">
                <h5 class="p-3"><i class="fas fa-sliders-h"></i> الفلاتر </h5>

                <b-card no-body class="border-radius-0" v-for="(fb, _index) in fabric_problems" :key="'fb'+_index">
                  <b-card-header header-tag="header" class="p-2" role="tab">
                    <a href="javascript:;" v-b-toggle="'accordion-'+_index" style="width: 100%;display: block;">{{ fb.name }}</a>
                  </b-card-header>
                  <b-collapse :id="'accordion-'+_index" accordion="my-accordion" role="tabpanel">
                    <b-card-body class="p-2">
                      <div class="d-block">
                        <button v-for="(row, index) in fb.attached_reasons" @click="selectAttachedReasons(row)" :key="'attached_reasons_'+row.id" class="btn btn-link m-1 p-1 text-dark text-decoration-none attached-reason" :class="{'attached-reason-active':isSelectedAttachedReasons(row)}">{{ row.name }}</button>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <!--                <b-card no-body class="border-radius-0">-->
                <!--                  <b-card-header header-tag="header" class="p-2" role="tab">-->
                <!--                    <a href="javascript:;" v-b-toggle.accordion-1>التلف</a>-->
                <!--                  </b-card-header>-->
                <!--                  <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">-->
                <!--                    <b-card-body class="p-2">-->
                <!--                      <div class="d-block">-->
                <!--                        <button v-for="(row, index) in damage_list" @click="selectDamage(row)" :key="'damage_list_'+index" class="btn btn-link m-1 p-1 text-dark text-decoration-none attached-reason" :class="{'attached-reason-active':isSelectedDamage(row.id)}">{{ row.name }}</button>-->
                <!--                      </div>-->
                <!--                    </b-card-body>-->
                <!--                  </b-collapse>-->
                <!--                </b-card>-->
                <!--                <b-card no-body class="border-radius-0">-->
                <!--                  <b-card-header header-tag="header" class="p-2" role="tab">-->
                <!--                    <a href="javascript:;" v-b-toggle.accordion-2>البقع</a>-->
                <!--                  </b-card-header>-->
                <!--                  <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">-->
                <!--                    <b-card-body class="p-2">-->
                <!--                      <div class="d-block">-->
                <!--                        <button v-for="(row, index) in stains_list" @click="selectStains(row)" :key="'stains_list_'+index" class="btn btn-link m-1 p-1 text-dark text-decoration-none attached-reason" :class="{'attached-reason-active':isSelectedStains(row.id)}">{{ row.name }}</button>-->
                <!--                      </div>-->
                <!--                    </b-card-body>-->
                <!--                  </b-collapse>-->
                <!--                </b-card>-->
                <!--                <b-card no-body class="border-radius-0">-->
                <!--                  <b-card-header header-tag="header" class="p-2" role="tab">-->
                <!--                    <a href="javascript:;" v-b-toggle.accordion-3>الفقاعات</a>-->
                <!--                  </b-card-header>-->
                <!--                  <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">-->
                <!--                    <b-card-body class="p-2">-->
                <!--                      <div class="d-block">-->
                <!--                        <button v-for="(row, index) in bubbles_list" @click="selectBubbles(row)" :key="'bubbles_list_'+index" class="btn btn-link m-1 p-1 text-dark text-decoration-none attached-reason" :class="{'attached-reason-active':isSelectedBubbles(row.id)}">{{ row.name }}</button>-->
                <!--                      </div>-->
                <!--                    </b-card-body>-->
                <!--                  </b-collapse>-->
                <!--                </b-card>-->
              </div>


              <div class="d-block border" style="margin-top: 100%;">
                <h5 class="p-3">اللون</h5>
                <div v-for="(row, index) in colors_list" :key="'color_'+index" class="color-item-show" :class="{'color-item-active':isSelectedColor(row)}">
                  <a @click="selectColor(row)" :style="{'background-color': row.color}" href="javascript:;" class="color-item"></a>
                </div>
              </div>
            </div>


            <div v-if="active_more_details_for_item" class="more-details-for-item">
              <div class="more-details-for-item-header d-flex justify-content-between">
                <h3>{{ $t('point_of_sales.edit_item_notes') }}</h3>
                <a href="javascript:;" class="btn btn-link text-dark" @click="closeMoreDetailsForItem"><i class="fas fa-times" style="padding: 0;"></i></a>
              </div>
              <div class="table-responsive">
                <table class="table border-0">
                  <thead style="background-color: transparent;">
                  <tr>
                    <th>{{ $t('point_of_sales.item') }}</th>
                    <th>{{ $t('point_of_sales.color') }}</th>
                    <th>{{ $t('point_of_sales.code') }}</th>
                    <th>{{ $t('point_of_sales.notes') }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>

                  <template v-if="selected_items[index_item_selected]">
                    <tr v-for="(row, index) in selected_items[index_item_selected].qty" :key="'more-details-for-item'+index">
                      <td><a href="javascript:;" @click="forSetColorAndReasonsActiveForCurrentItem(index)" class="text-dark"><b>{{ selected_items[index_item_selected].name }}</b></a></td>
                      <td>
                        <multiselect v-model="selected_items[index_item_selected].colors[index]"
                                     :placeholder="$t('point_of_sales.color')"
                                     label="name"
                                     track-by="id"
                                     :options="colors_list"
                                     @input="refreshCart"
                                     :multiple="true"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false">
                        </multiselect>
                      </td>
                      <td>
                        <input type="text" class="form-control" @input="refreshCart" v-model="selected_items[index_item_selected].notes[index]" @change="refreshCart">
                      </td>
                      <td>
                        <input type="text" class="form-control" @input="refreshCart" v-model="selected_items[index_item_selected].code[index]" @change="refreshCart">
                      </td>
                      <td style="display: flex;">
                        <a href="javascript:;" class="text-primary m-2" @click="copyAttributes(index_item_selected, index)" v-b-tooltip.hover :title="$t('copy')"><i class="fas fa-copy text-0C6F58"></i></a>
                        <a href="javascript:;" class="text-primary m-2" @click="showAttachedResourcesModal(index)" v-b-tooltip.hover :title="$t('point_of_sales.attached_reasons')"><i class="fas fa-eye text-FFB803"></i></a>
                      </td>
                    </tr>

                  </template>

                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
      <b-modal ref="calcModal" size="sm" hide-footer hide-header no-close-on-backdrop :title="''">
        <div class="d-flex justify-content-end">
          <a href="javascript:;" class="text-dark" @click="hideCalcModal">
            <i class="fa fa-times text-dark"></i>
          </a>
        </div>
        <div v-if="item_action == 'dis_val' || item_action == 'dis_per'" class="pt-3 text-center">
          <b-button-group>
            <b-button @click="addAction('dis_per')" :variant="item_action == 'dis_per' ? 'primary': 'outline-primary'">خصم نسبة</b-button>
            <b-button @click="addAction('dis_val')" :variant="item_action == 'dis_val' ? 'primary': 'outline-primary'">خصم قيمة</b-button>
          </b-button-group>
        </div>
        <div class="pt-3">
          <div class="text-center mb-7"><span style="border-bottom: 1px solid rgb(0, 0, 0);font-size: 30px;min-width: 54px !important;display: block;margin: 0 auto;width: max-content;">{{ num_calc ? num_calc : '0.0' }}</span></div>
          <div class="buttons">
            <div class="button" @click="selectNumber('9')">9</div>
            <div class="button" @click="selectNumber('8')">8</div>
            <div class="button" @click="selectNumber('7')">7</div>
            <div class="button" @click="restCalc">←</div>
            <div class="button" @click="selectNumber('6')">6</div>
            <div class="button" @click="selectNumber('5')">5</div>
            <div class="button" @click="selectNumber('4')">4</div>
            <div class="button" @click="selectNumber('3')">3</div>
            <div class="button" @click="selectNumber('2')">2</div>
            <div class="button" @click="selectNumber('1')">1</div>
            <div id="equal" class="button grid-h-3 d-flex justify-content-center align-items-center" @click="selectNumber('enter')">
              <i class="fas fa-level-down-alt text-white" style="font-size: 24px;"></i>
            </div>
            <div class="button grid-v-2" @click="selectNumber('0')">0</div>
            <div class="button" @click="selectNumber('.')">.</div>
          </div>

        </div>
      </b-modal>
      <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
        <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer" :type="type"></customer-form>
      </b-modal>
      <b-modal ref="paymentModal" size="xl" hide-footer hide-header :body-class="'p-0 overflow-hidden-with-radius'" :title="$t('point_of_sales.payment')">
        <pos-payment-form v-if="is_refund_available == false"
                          @hide-modal="hidePaymentModal()"
                          @hide-save-modal="hidePrintPaymentModal()"
                          @reset-list="resetList()"
                          @print-showing-payed-invoice="printShowingPayedInvoiceScreen()"
                          :items="selected_items"
                          :total-dis="total_dis"
                          :total-items="total_items"
                          :shipping-val="shipping_val"
                          :customer="customer"
                          :currency-id="currency_id"
                          :currency-name="currency"
                          :total-tax="total_tax"
                          :total-final="total_final"
                          :more-details="more_details"
                          :notes="notes"
                          :session-id="session_id"
                          :is-apply-taxes-invoice="is_apply_taxes_invoice"
                          :payment-methods-list.sync="paymentMethodsList"
                          :another-data.sync="this.another_data"
        ></pos-payment-form>
      </b-modal>
      <b-modal ref="refundModal" size="xl" hide-footer :title="$t('point_of_sales.payment')">
        <pos-refund-form v-if="is_refund_available == true"
                         @hide-modal="hideRefundModal()"
                         @hide-save-modal="hidePrintRefundModal()"
                         @reset-list="resetList()"
                         :items="selected_items"
                         :total-dis="total_dis"
                         :total-items="total_items"
                         :shipping-val="shipping_val"
                         :customer="customer"
                         :currency-id="currency_id"
                         :currency-name="currency"
                         :total-tax="total_tax"
                         :previous-invoice-total="invoice_total"
                         :invoice-id="invoice_id"
                         :total-final="total_final"
        ></pos-refund-form>
      </b-modal>
      <b-modal ref="productInformationModal" size="lg" hide-footer :title="$t('product_information')">
        <product-information @hide-modal="hideProductInformationModal()" :id="productId"></product-information>
      </b-modal>
      <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
        <item-form-laundry-modal @hide-modal="hideNewItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form-laundry-modal>
      </b-modal>
<!--      <b-modal ref="pricingInformationModal" size="md" hide-footer :title="$t('point_of_sales.pricing_information')">-->
<!--        <div class="row">-->
<!--          <div class="col-md-12 pt-3">-->
<!--            <div>-->
<!--              <div class="row mb-5">-->
<!--                <div class="col-md-6"><b>{{ $t('point_of_sales.subTotal') }}</b></div>-->
<!--                <div class="col-md-6">{{ total_items }}</div>-->
<!--              </div>-->
<!--              <div class="row mb-5">-->
<!--                <div class="col-md-6"><b>{{ $t('point_of_sales.discount') }}</b></div>-->
<!--                <div class="col-md-6 d-flex">-->
<!--                  <input type="number" class="form-control" @input="calcItemsSelected" v-model="more_details.discount_value">-->
<!--                  <select name="" id="discount_types" @change="calcItemsSelected" v-model="more_details.discount_type" class="custom-select">-->
<!--                    <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--                <span v-if="is_show_validation_dis_value" class="fv-plugins-message-container invalid-feedback">-->
<!--                                        {{ $t('discount_must_be_less_than_or_equal_total_invoice') }}-->
<!--                                    </span>-->
<!--              </div>-->
<!--              <div class="row mb-5">-->
<!--                <div class="col-md-6"><b>{{ $t('point_of_sales.shipping') }}</b></div>-->
<!--                <div class="col-md-6"><input type="number" @input="calcItemsSelected" class="form-control" v-model="shipping_val"></div>-->
<!--              </div>-->
<!--              <div class="row mb-5">-->
<!--                <div class="col-md-6"><b>{{ $t('point_of_sales.tax') }}</b></div>-->
<!--                <div class="col-md-6">-->
<!--                  <multiselect v-model="more_details.tax"-->
<!--                               :placeholder="$t('point_of_sales.tax')"-->
<!--                               label="name"-->
<!--                               track-by="id"-->
<!--                               :options="taxes"-->
<!--                               :multiple="false"-->
<!--                               :taggable="false"-->
<!--                               :show-labels="false"-->
<!--                               :show-no-options="false"-->
<!--                               :show-no-results="false"-->
<!--                               @input="calcItemsSelected"-->
<!--                               @search-change="getTaxes($event)">-->
<!--                  </multiselect>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row mb-5">-->
<!--                <div class="col-md-6"><b>{{ $t('point_of_sales.total') }}</b></div>-->
<!--                <div class="col-md-6">{{ total_final }}</div>-->
<!--              </div>-->
<!--              <div class="row mt-10">-->
<!--                <div class="col-md-12 text-center">-->
<!--                  <b-button variant="primary" @click="hidePricingInformationModal">{{ $t('point_of_sales.confirm') }}</b-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </b-modal>-->
      <b-modal ref="notesCartModal" size="lg" hide-footer :title="$t('notes')">
        <div class="col-lg-12 mb-5">
          <textarea v-model="notes" class="form-control"></textarea>
        </div>
        <div class="col-lg-12 mb-5 d-flex justify-content-center">
          <button class="btn btn-primary" @click="hideNotesCartModal">{{ $t('confirm') }}</button>

        </div>
      </b-modal>
      <b-modal ref="changeDeliveryReceivedModal" size="lg" hide-footer :title="$t('received_and_delivery_dates')">

        <div class="d-flex mb-5">
          <button class="btn" :class="more_details_for == 'delivery' ? 'btn-primary': 'btn-link text-dark'" @click="more_details_for = 'delivery'">{{ $t('delivery') }}</button>
          <button class="btn" :class="more_details_for == 'received' ? 'btn-primary': 'btn-link text-dark'" @click="more_details_for = 'received'">{{ $t('received') }}</button>
        </div>

        <div class="row" v-if="more_details_for == 'delivery'">
          <div class="col-md-8 mb-5">

            <b-calendar v-model="more_details.delivery_date" block class="border rounded p-2" :locale="LANG"></b-calendar>
          </div>
          <div class="col-md-4 mb-5">
            <b-time v-model="more_details.delivery_time" block v-bind="labels[LANG] || {}" :locale="LANG"></b-time>

            <h3 class="text-center mt-10">{{ more_details.delivery_date }} {{ more_details.delivery_time }}</h3>
          </div>
        </div>

        <div class="row" v-if="more_details_for == 'received'">
          <div class="col-md-8 mb-5">

            <b-calendar v-model="more_details.received_date" block class="border rounded p-2" :locale="LANG"></b-calendar>
          </div>
          <div class="col-md-4 mb-5">
            <b-time v-model="more_details.received_time" block v-bind="labels[LANG] || {}" :locale="LANG"></b-time>

            <h3 class="text-center mt-10">{{ more_details.received_date }} {{ more_details.received_time }}</h3>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <b-button class="mt-2 mr-1" variant="secondary" @click="hideChangeDeliveryReceivedModal">{{ $t('close') }}</b-button>
        </div>
      </b-modal>

      <b-modal ref="attachedReasonsModal" size="lg" hide-footer :title="$t('point_of_sales.attached_reasons')">
        <div class="table-responsive">
          <table class="table border-0">
            <thead style="background-color: transparent;">
            <tr>
              <th>{{ $t('point_of_sales.fabric_problem') }}</th>
              <th>{{ $t('point_of_sales.reason') }}</th>
            </tr>
            </thead>
            <tbody>

            <template v-if="index_of_qty != null && selected_items[index_item_selected] && selected_items[index_item_selected].attached_reasons && selected_items[index_item_selected].attached_reasons[index_of_qty]">
              <tr v-for="(row, index) in selected_items[index_item_selected].attached_reasons[index_of_qty]" :key="'more-details-for-item'+index">
                <td>
                  <multiselect v-model="selected_items[index_item_selected].attached_reasons[index_of_qty][index]"
                               :key="'attached_reasons'+index_of_qty+index"
                               :placeholder="$t('point_of_sales.type')"
                               label="name"
                               track-by="id"
                               :disabled="true"
                               :options="fabric_problems"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                </td>
                <td>
                  <multiselect v-model="selected_items[index_item_selected].attached_reasons[index_of_qty][index].attached_reasons"
                               :placeholder="$t('point_of_sales.reason')"
                               label="name"
                               track-by="id"
                               :key="'reason'+index_of_qty+index"
                               :options="getAttachedReasonsFromFabric(row.id)"
                               @input="refreshCart"
                               :multiple="true"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                </td>
              </tr>

            </template>

            </tbody>
          </table>
        </div>
      </b-modal>

      <b-modal ref="itemsGroupModal" size="lg" hide-footer :title="''">
        <div class="row">
          <div class="item" v-for="row in items_group" :key="'items_group'+row.id" @click="selectItem(row)">
            <div>
              <div class="p-2">{{ row.name }}</div>
              <img :src="row.image_url" class="img-fluid" alt="Image">
            </div>
          </div>

        </div>
      </b-modal>
    </div>
    <div v-if="mainScreen == 'cleaning'">
      <div class="h-100">
        <pos-cleaning :currency="currency"></pos-cleaning>
      </div>
    </div>
    <div v-if="mainScreen == 'ready'">
      <div class="h-100">
        <pos-ready :currency="currency"></pos-ready>
      </div>
    </div>
    <div v-if="mainScreen == 'pickups'">
      <pos-pickups :currency="currency"></pos-pickups>
    </div>
    <div v-if="mainScreen == 'details'">
      <pos-details v-if="session_id" :currency="currency" :session-id="session_id"></pos-details>
    </div>
    <div v-if="mainScreen == 'main'">
      <main-screen></main-screen>
    </div>
  </div>

</template>
<script>

import 'vue-multiselect/dist/vue-multiselect.min.css';

import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import ApiService from "@/core/services/api.service";
import CustomerForm from "@/view/content/forms/CustomerForm";
import POSPaymentForm from "@/view/content/forms/POSLaunderyPaymentForm";
import POSRefundForm from "@/view/content/forms/POSLaunderyRefundForm";
import ProductInformationModal from "@/view/content/modals/ProductInformationModal";
import POSCleaning from "./Cleaning.vue";
import POSReady from "./Ready.vue";
import POSPickups from "./Pickups.vue";
import POSDetails from "./Details.vue";
import ItemFormLaundryModal from "@/view/content/forms/ItemFormLaundry.vue";
import MainScreen from "@/view/pages/modules/pos/pointOfSalesLaundry/mainScreen.vue";
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";

export default {
  name: "index-point-of-sales-laundry",
  components: {
    MainScreen,
    ItemFormLaundryModal,
    'customer-form': CustomerForm, 'pos-payment-form': POSPaymentForm,
    'pos-refund-form': POSRefundForm, 'product-information': ProductInformationModal,
    'pos-cleaning': POSCleaning, 'pos-ready': POSReady, 'pos-pickups': POSPickups,
    'pos-details': POSDetails,
  },
  props: {
    innerUser: null,
    innerCurrency: null,
    currencyId: null,
    sessionId: null,
    indexScreen: null,
    isDisabled: {default: true, type: Boolean},
    handlingData: {type: Function},
    setTypeInvoice: {type: Function},
    currencyCode: {type: String},
    mainScreen: {type: String},
    printShowingPayedInvoice: {type: Function},
    propsData: {type: Object},
  },
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRouteInvoice: 'sales/sales_invoices',
      data: {
        name: null,
        type: 1,
        user_id: null,
        barcode: null,
      },
      lang: this.$i18n.locale,
      items_list: [],
      laundry_services: [],

      damage_list: [],
      damage_selected_for_current_item: [],
      stains_list: [],
      stains_list_selected_for_current_item: [],
      bubbles_list: [],
      bubbles_list_selected_for_current_item: [],

      colors_list: [],
      colors_list_selected_for_current_item: [],

      searchBox: [
        {
          "id": 1,
          "name": this.$t('products'),
        },
        // {
        //   "id": 2,
        //   "name": this.$t('invoice'),
        // },
        {
          "id": 3,
          "name": this.$t('customer'),
        }
      ],
      categories: [],
      customers: [],
      items: [],
      invoices: [],
      selected_items: [],
      total_items: 0,
      total_dis: 0,
      total_final: 0,
      shipping_val: 0,
      total_tax: 0,
      invoice_total: 0,
      invoice_subtotal: 0,
      index_item_selected: null,
      index_sub_item_selected: 0,
      item_action: null,

      total_amount_before_discount: 0,
      total_amount_after_discount: 0,
      invoice_total_after_taxes: 0,
      invoice_total_before_taxes: 0,
      piece_no: 0,

      invoice_url: null,
      client_name: this.$t('pos_client'),
      type: 'point_of_sale',
      user: [],
      currency: 'SAR',
      currency_id: null,
      date_now: '',

      show_loader: false,
      isClient: false,
      num_calc: '',
      is_show_calc: false,
      customer: null,
      show_product_info: false,
      productId: null,
      is_refund_available: false,
      invoice_id: null,
      session_id: null,
      laundry_service_id: null,
      laundry_service_symbol: null,
      active_more_details_for_item: false,
      more_details: {
        received_date: null,
        received_time: null,
        delivery_date: null,
        delivery_time: null,
        discount_value: 0,
        discount_type: 2,
        tax: null,
        tax_id: null,
      },

      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      taxes: [],
      service_tax: null,
      service_tax_id: null,
      notes: null,
      currency_code: null,

      more_details_for: 'delivery',
      LANG: localStorage.getItem("language") || 'ar',
      labels: {
        'ar': {
          labelHours: 'ساعات',
          labelMinutes: 'الدقائق',
          labelSeconds: 'ثواني',
          labelAmpm: 'صباحا مساء',
          labelAm: 'ص',
          labelPm: 'م',
          labelIncrement: 'زيادة',
          labelDecrement: 'إنقاص',
          labelSelected: 'الوقت المحدد',
          labelNoTimeSelected: 'لا وقت المختار'
        },
      },
      inner_more_details: {
        discount_value: 0,
        discount_type: 2,
      },
      is_show_validation_dis_value: false,
      pagination: {
        current_page: 1,
        last_page: 0,
      },
      fabric_problems: [],
      attached_reasons_selected_for_current_item: [],
      is_apply_taxes_invoice: false,
      paymentMethodsList: [],
      index_of_qty: null,
      items_group: [],
      total_tax_type_val: [],
    }
  },
  computed: {
    another_data(){
      return {
        total_amount_before_discount: this.total_amount_before_discount,
        total_amount_after_discount: this.total_amount_after_discount,
        invoice_total_after_taxes: this.invoice_total_after_taxes,
        invoice_total_before_taxes: this.invoice_total_before_taxes,
        total_tax_type_val: this.total_tax_type_val,
        piece_no: this.piece_no,
      }
    },
    activePaymentBtn() {
      if (this.is_refund_available) {
        let _selected_items_returned = this.selected_items.filter(row => row.is_return == true);
        if (_selected_items_returned.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.selected_items.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isApplyTaxesInvoice() {
      let _status = this.is_apply_taxes_invoice;
      return _status;
    },
  },
  watch: {
    // "data.name": function (val) {
    //   if (!val) {
    //     this.categories = this.getCategories();
    //   }
    // },
    isEnabled: function (val) {
      if (val) {
        this.initialOnClickKeyboard();
        this.$barcodeScanner.init(this.onBarcodeScanned);
      } else {
        this.index_item_selected = null;
      }
    },
    selected_items: {
      handler(val) {
        if (val.length > 0) {
          this.calcItemsSelected();
        } else {
          this.active_more_details_for_item = false;
        }
      },
      deep: true
    },
    is_show_calc: function (val) {
      if (val) {
        this.showCalcModal();
      } else {
        this.hideCalcModal();
        this.num_calc = '';
      }
    },
    "more_details.tax": function (val) {
      if (val) {
        this.more_details.tax_id = val.id;
      } else {
        this.more_details.tax_id = null;
      }
    },
    'data.type': function (val) {
      this.data.name = null;
      if (val == 3) {
        this.getClient();
        this.$emit('set-type-invoice', false);
      } else {
        this.searchItems();
        this.isClient = false;

      }
    },
    isClient: function (val) {
      if (val) {
        this.data.type = 3;
      }
    }
  },

  methods: {
    // onLogout() {
    //     this.$store
    //         .dispatch(LOGOUT)
    //         .then(() => this.$router.push({name: "login"}));
    // },
    // printInvoice() {
    //     let iframe = document.getElementById('invoice');
    //     iframe.contentWindow.print();
    // },

    ...dataTypeNumbers,
    nextPage() {
      if (this.pagination.last_page > this.pagination.current_page) {
        this.pagination.current_page = this.pagination.current_page + 1;
      }
      this.search();
    },

    previousPage() {
      if (this.pagination.current_page > 1) {
        this.pagination.current_page = this.pagination.current_page - 1;
      }
      this.search();
    },
    resetPagination() {
      this.pagination = {
        current_page: 1,
        last_page: 0,
      }
    },
    getClient() {
      this.isClient = true;
      this.selected_items = [];
      this.total_items = 0;
      this.total_final = 0;
      this.total_dis = 0;
      this.shipping_val = 0;
      this.invoice_total = 0;
      this.client_name = this.$t('pos_client');
      this.is_show_calc = false;
      this.is_refund_available = false;
      this.getCustomers();
    },
    switchOnProducts(name, row) {
      this.client_name = name;
      this.customer = row;
      this.isClient = false;
      this.data.type = 1;
    },
    getDataAfterCreateNewCustomer(obj) {
      this.customer = obj;
      this.client_name = obj.fullname;
      // let promise = this.getCustomers();
      this.getCustomers();
      this.isClient = false;
      this.data.type = 1;
    },
    async search() {
      if (this.data.type == 1 || this.data.type == 2) {
        this.isClient = false;
      }
      if (this.isClient) {
        this.getCustomers();
      } else {
        this.searchItems();
      }
    },
    async searchItems() {
      this.showLoader();
      this.is_show_calc = false;
      this.item_action = null;
      await ApiService.get(`${this.mainRouteDependency}/items_laundry_service/${this.laundry_service_id}`, {
        params: {
          page: this.pagination.current_page,
          ...this.data
        }
      }).then((response) => {
        if (this.data.type == 1) {
          this.items = response.data.data.data;
          // this.categories = [];
          this.invoices = [];
        } else {
          // this.categories = [];
          this.items = [];
          this.invoices = response.data.data.data;
          this.isClient = false;
        }
        this.pagination.last_page = response.data.data.last_page
        this.hideLoader();
      });
    },
    showLoader() {
      this.show_loader = true;
    },
    hideLoader() {
      this.show_loader = false;
    },
    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showCalcModal() {
      this.$refs['calcModal'].show()
    },
    hideCalcModal() {
      this.is_show_calc = false;
      this.$refs['calcModal'].hide();
    },
    showPaymentModal() {
      this.disabledOnClickKeyboard();
      this.$refs['paymentModal'].show();
    },
    hidePaymentModal() {
      this.initialOnClickKeyboard();
      this.$refs['paymentModal'].hide();
    },
    hidePrintPaymentModal() {
      this.$emit('handling-data', 'payment');
      this.$refs['paymentModal'].hide();
      this.resetSectionsColorsDamageStains();
    },
    showRefundModal() {
      this.disabledOnClickKeyboard();
      this.$refs['refundModal'].show();
    },
    hideRefundModal() {
      this.initialOnClickKeyboard();
      this.$refs['refundModal'].hide();
    },
    hidePrintRefundModal() {
      this.$emit('handling-data', 'refund');
      this.$refs['refundModal'].hide();
    },
    showProductInformationModal() {
      this.$refs['productInformationModal'].show();
    },
    hideProductInformationModal() {
      this.$refs['productInformationModal'].hide();
    },
    // showPricingInformationModal() {
    //   this.$refs['pricingInformationModal'].show();
    // },
    // hidePricingInformationModal() {
    //   this.$refs['pricingInformationModal'].hide();
    // },
    showNotesCartModal() {
      this.$refs['notesCartModal'].show();
    },
    hideNotesCartModal() {
      this.$refs['notesCartModal'].hide();
    },
    showChangeDeliveryReceivedModal() {
      this.disabledOnClickKeyboard();
      this.$refs['changeDeliveryReceivedModal'].show();
    },
    hideChangeDeliveryReceivedModal() {
      this.initialOnClickKeyboard();
      this.$refs['changeDeliveryReceivedModal'].hide();
    },

    showNewItemModal() {
      this.disabledOnClickKeyboard();
      this.$refs['modalItem'].show()
    },
    hideNewItemModal() {
      this.initialOnClickKeyboard();
      this.$refs['modalItem'].hide();
    },

    showAttachedResourcesModal(index_qty) {
      this.index_of_qty = index_qty;
      this.$refs['attachedReasonsModal'].show();
    },
    hideAttachedResourcesModal() {
      this.index_of_qty = null;
      this.$refs['attachedReasonsModal'].hide();
    },

    showItemsGroupModal(row) {
      this.items_group = row.laundry ? row.laundry : [];
      this.$refs['itemsGroupModal'].show();
    },
    hideItemsGroupModal() {
      this.items_group = [];
      this.$refs['itemsGroupModal'].hide();
    },

    getDataAfterCreateNewItem(object) {
      this.getItems();
    },
    /**
     * MoreDetailsForItem => hide and show
     */
    closeMoreDetailsForItem() {
      this.active_more_details_for_item = false;
    },
    showMoreDetailsForItem(index) {
      this.index_item_selected = index;
      this.active_more_details_for_item = true;
    },
    reset() {
      this.data.name = null;
      // this.categories = this.getCategories();
      this.items = this.getItems();
      this.data.type = 1;
      this.invoices = [];
      this.getCustomers();
    },

    getCustomers() {
      this.showLoader();
      ApiService.get(`${this.mainRouteDependency}/customers_default`, {params: {filter: this.data.name, page: this.pagination.current_page}}).then((response) => {
        this.customers = response.data.data.data;
        this.pagination.last_page = response.data.data.last_page;
        // response.data.data.map((row) => {
        //   if (row.type == 1) {
        //     this.switchOnProducts(row.name, row);
        //   }
        // })

        this.hideLoader();
      });
    },
    // getCategories() {
    //   if (this.data.type == 1) {
    //     this.showLoader();
    //     ApiService.get(`${this.mainRouteDependency}/categories_sale`).then((response) => {
    //       this.categories = response.data.data;
    //       this.hideLoader();
    //     });
    //   }
    //
    // },
    getDefaultCustomer() {
      ApiService.get(`${this.mainRouteDependency}/pos_default_customer`).then((response) => {
        this.switchOnProducts(response.data.data.name, response.data.data)
      });
    },
    // getItemsByCategory(id) {
    //   ApiService.get(`${this.mainRouteDependency}/items_by_category/${id}`).then((response) => {
    //     this.items = [];
    //     this.items = response.data.data;
    //     this.categories = [];
    //   });
    // },
    chooseLaundryServices(row) {
      this.laundry_service_id = row.id;
      this.laundry_service_symbol = row.symbol;
      if (row.tax) {
        this.service_tax = row.tax;
        this.service_tax_id = row.tax.id;
      }
      this.isClient = false;
      this.data.type = 1;
      setTimeout(() => {
        this.getItems();
      }, 100)
    },
    getItems() {
      this.showLoader();
      ApiService.get(`${this.mainRouteDependency}/items_laundry_service/${this.laundry_service_id}`).then((response) => {
        this.items = response.data.data.data;
        this.pagination.last_page = response.data.data.last_page
        this.hideLoader();
      });
    },
    getDamage() {//base/dependency/attached_reasons?type=1
      ApiService.get(`${this.mainRouteDependency}/attached_reasons?type=1`).then((response) => {
        this.damage_list = response.data.data;
      });
    },
    getStains() {//base/dependency/attached_reasons?type=2
      ApiService.get(`${this.mainRouteDependency}/attached_reasons?type=2`).then((response) => {
        this.stains_list = response.data.data;
      });
    },
    getBubbles() {//base/dependency/attached_reasons?type=3
      ApiService.get(`${this.mainRouteDependency}/attached_reasons?type=3`).then((response) => {
        this.bubbles_list = response.data.data;
      });
    },
    getFabricProblems() {
      ApiService.get(`${this.mainRouteDependency}/fabric_problems_with_reasons`).then((response) => {
        this.fabric_problems = response.data.data;
      });
    },

    async getLaundryServices() {
      await ApiService.get(`${this.mainRouteDependency}/laundry_services`).then((response) => {
        this.laundry_services[0] = response.data.data.filter((row, index) => {
          if (index <= 4) {
            return row;
          }
        });
        this.laundry_services[1] = response.data.data.filter((row, index) => {
          if (index > 4) {
            return row;
          }
        });

        if (response.data.data && response.data.data[0]) {
          if (response.data.data[0].id) {
            this.laundry_service_id = response.data.data[0].id;
            this.laundry_service_symbol = response.data.data[0].symbol ? response.data.data[0].symbol : null;
            this.service_tax = response.data.data[0].tax ? response.data.data[0].tax : null;
            this.service_tax_id = (response.data.data[0].tax && response.data.data[0].tax.id)? response.data.data[0].tax.id : null;
          }
        }
      });
    },
    getLaundryServicesColors() {
      ApiService.get(`${this.mainRouteDependency}/laundry_services_colors`).then((response) => {
        this.colors_list = response.data.data;
      });
    },

    // getPOSSetting() {
    //     this.showLoader();
    //     ApiService.get(this.mainRouteDependency + '/pos_setting').then((response) => {
    //         // if (response.data.data.client_name) {
    //         //     this.switchOnProducts(response.data.data.client_name, response.data.data.client);
    //         // }
    //         this.hideLoader();
    //     });
    // },
    getItemsInvoice(id) {
      this.selected_items = [];
      ApiService.get(this.mainRouteInvoice + '/' + id).then((response) => {
        if (response.data.data.items_list) {
          let _invoice_id = response.data.data.id;
          let _customer_id = response.data.data.customer_id;
          let _inventory_id = response.data.data.inventory_id;
          this.invoice_total = response.data.data.invoice_total;
          this.total_tax = response.data.data.invoice_total_taxes;
          this.invoice_id = response.data.data.id;

          this.customer_id = response.data.data.customer_id;
          this.client_name = response.data.data.customer ? response.data.data.customer.name : this.$t('pos_client');
          this.customer = response.data.data.customer;

          response.data.data.items_list.forEach((row) => {
            // let _tax_applied = (row.tax_applied && row.tax_applied[0]) ? row.tax_applied[0] : null;
            // let _include_tax = "0";
            // if (_tax_applied){
            //   _include_tax = _tax_applied.include_tax;
            // }
            this.selected_items.push({
              id: row.id,
              item_id: row.item ? row.item.id : 0,
              invoice_id: _invoice_id,
              customer_id: _customer_id,
              service_id: this.laundry_service_id,
              inventory_id: _inventory_id,
              name: row.item ? row.item.name : '',
              unit_price: row.service_price,
              discount_type: row.discount_type,
              discount_val: row.discount_value,
              dis_val: (row.discount_type == 3) ? row.discount_value : 0,
              dis_per: (row.discount_type == 2) ? row.discount_value : 0,
              subtotal: row.subtotal_before_tax,
              qty: row.qty ? parseInt(row.qty) : 0,
              total_taxes: row.total_taxes ? row.tax.total_taxes : 0,
              tax: row.tax,
              include_taxes: row.include_taxes,
              subtotal_before_discount: row.subtotal_before_discount,
              subtotal_after_discount: row.subtotal_after_discount,
              // tax_2: row.tax_2,
              tax_val: row.amount_tax ? row.amount_tax : 0, //(row.subtotal * (row.total_taxes ? (parseFloat(row.total_taxes) / 100) : 0)).toFixed(2),
              amount_tax: row.amount_tax ? row.amount_tax : 0,
            });
          });
          this.calcItemsSelected();
        }
      });
    },

    selectItem(row) {
      if (!this.customer) {
        return false;
      }
      if (!row.service_price) return;
      // let _ids = [];
      // _ids = this.selected_items.map((row) => {
      //   return {id: row.id, service_id: row.service_id};
      // });
      let _index = this.selected_items.findIndex(item => (item.id == row.id && item.service_id == this.laundry_service_id));
      let _fabric_problems = this.getNewFabricProblemsEmpty();

      if (_index >= 0) {

        // if (parseInt(row.available_stock_qty) > parseInt(this.selected_items[_index].qty) && parseFloat(row.service_price) > 0) {
        this.selected_items[_index].service_id = this.laundry_service_id;
        this.selected_items[_index].qty = parseInt(this.selected_items[_index].qty) + 1;
        this.selected_items[_index].subtotal = this.selected_items[_index].qty * (this.selected_items[_index].unit_price ? parseFloat(this.selected_items[_index].unit_price) : 0);

        this.selected_items[_index].tax = this.service_tax;
        this.selected_items[_index].tax_val = (this.selected_items[_index].subtotal * (this.selected_items[_index].total_taxes ? (parseFloat(this.selected_items[_index].total_taxes) / 100) : 0)).toFixed(2);
        this.selected_items[_index].tax_id = this.service_tax_id;

        this.selected_items[_index].attached_reasons.push([..._fabric_problems]);
        this.selected_items[_index].code.push('');
        this.selected_items[_index].notes.push('');
        this.selected_items[_index].colors.push([]);
        this.selected_items[_index].damages.push([null, null, null]);
        this.selected_items[_index].stains.push([null, null, null]);
        this.selected_items[_index].bubbles.push([null, null, null]);
        this.selected_items[_index].additional_cost.push([null, null, null]);

        // this.selected_items[_index].colors = [[null,null,null]];
        // this.selected_items[_index].damages = [[null,null,null]];
        // this.calcItemsSelected();
        /**
         * get tax for item
         */
        this.changeQty(_index, this.selected_items[_index].qty);
        // }
      } else {
        // if (row.available_stock_qty > 0 && parseFloat(row.service_price) > 0) {
        let _row = {...row};
        _row.service_id = this.laundry_service_id;
        _row.unit_price = row.service_price;
        _row.qty = 1;
        _row.dis_per = 0;
        _row.dis_val = 0;
        _row.discount_type = 2;
        _row.discount_val = 0;

        _row.amount_excluded_tax = 0;
        _row.amount_included_tax = 0;
        _row.subtotal_before_tax = 0;
        _row.subtotal_after_discount = 0;
        _row.amount_tax = 0;
        _row.total_item_after_discount_with_excluded = 0;
        _row.tax_applied = [];

        _row.subtotal = _row.qty * parseFloat(_row.unit_price);

        _row.subtotal_before_discount = _row.subtotal;
        _row.subtotal_after_discount = _row.subtotal;

        _row.tax_val = (_row.subtotal * (_row.total_taxes ? (parseFloat(_row.total_taxes) / 100) : 0)).toFixed(2);
        _row.tax = this.service_tax;
        _row.tax_id = this.service_tax_id;
        _row.tax_val = 0;

        _row.laundry = [];
        _row.servcies = [];
        _row.attached_reasons = [];
        _row.attached_reasons.push([..._fabric_problems]);
        _row.symbol = this.laundry_service_symbol;
        _row.colors = [[]];
        _row.damages = [[null, null, null]];
        _row.stains = [[null, null, null]];
        _row.bubbles = [[null, null, null]];
        _row.additional_cost = [[null, null, null]];
        _row.code = [''];
        _row.notes = [''];

        _row.insert_time = this.$moment().format('h:mm');
        this.selected_items.push(_row);

        let _index = this.selected_items.findIndex(item => item.id == row.id);
        /**
         * get tax for item
         */
        this.getTaxValue(_index);
        // }

      }
      this.forSetColorAndReasonsActiveForCurrentItem();
    },

    getNewFabricProblemsEmpty() {
      let _fabric_problems = [];
      this.fabric_problems.forEach((row) => {
        let _obj = {...row};
        _obj.attached_reasons = [];
        _fabric_problems.push(_obj)
      });
      return _fabric_problems;
    },

    selectAttachedReasons(row) {

      if (this.index_item_selected != null && this.index_item_selected >= 0 && this.selected_items.length) {
        if (!this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected]) {
          this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected] = [];
        }

        /**
         * this is used to remove reason
         * and check the attached_reasons empty remove the parent
         * _fabric_problem_id : parent id (main object)
         * cc variable to get reason
         */
        let cc = null;
        let _fabric_problem_id = null;
        this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected].forEach((_row) => {
          let _find_cc = _row.attached_reasons.find((_reason) => _reason.id == row.id);
          if (_find_cc) {
            cc = _find_cc
            _fabric_problem_id = _row.id;
            let _fabric_problem_index = this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected].findIndex((a) => a.id == _fabric_problem_id);
            if (_fabric_problem_index >= 0) {
              let _attached_reasons_index = this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected][_fabric_problem_index].attached_reasons.findIndex((_b) => _b.id == row.id);
              if (_attached_reasons_index >= 0) {
                this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected][_fabric_problem_index].attached_reasons.splice(_attached_reasons_index, 1);

                /******* remove parent if attached_reasons in empty ********/
                // if (this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected][_fabric_problem_index].attached_reasons.length == 0){
                //   this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected].splice(_fabric_problem_index,1)
                // }
              }
            }

          }
        });

        /**
         * this is used to add new reason and check the parent was added
         * _fabric_problem_id : parent id (main object)
         * cc variable to get reason
         */
        if (!cc && !_fabric_problem_id) {
          this.fabric_problems.forEach((_row) => {
            let _find_cc = _row.attached_reasons.find((_reason) => _reason.id == row.id);
            if (_find_cc) {
              cc = _find_cc
              _fabric_problem_id = _row.id;
              let _fabric_problem_index = this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected].findIndex((a) => a.id == _fabric_problem_id);
              if (_fabric_problem_index >= 0) {
                this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected][_fabric_problem_index].attached_reasons.push(row);
              } else {
                let new_row = {..._row};
                new_row.attached_reasons = [];
                new_row.attached_reasons.push(row);

                this.selected_items[this.index_item_selected].attached_reasons[this.index_sub_item_selected].push(new_row);
              }
            }
          });
        }
        this.refreshCart();
      }
    },

    selectDamage(row) {
      // this.index_sub_item_selected = this.index_sub_item_selected ? this.index_sub_item_selected : 0;

      if (this.index_item_selected != null && this.index_item_selected >= 0 && this.selected_items.length) {
        if (!this.selected_items[this.index_item_selected].damages) {
          this.selected_items[this.index_item_selected].damages[this.index_sub_item_selected] = [null, null, null];
        }
        let _ids = this.selected_items[this.index_item_selected].damages[this.index_sub_item_selected];
        if (_ids.includes(row.id)) {
          /// remove attached reasons
          let _index = this.selected_items[this.index_item_selected].damages[this.index_sub_item_selected].findIndex(item => item == row.id);
          this.selected_items[this.index_item_selected].damages[this.index_sub_item_selected][_index] = null;
        } else {
          /// add attached reasons
          let _index = this.selected_items[this.index_item_selected].damages[this.index_sub_item_selected].findIndex(item => item == null);
          if (_index >= 0) {
            this.selected_items[this.index_item_selected].damages[this.index_sub_item_selected][_index] = row.id;
          } else {
            this.selected_items[this.index_item_selected].damages[this.index_sub_item_selected][0] = row.id;
          }
        }
        this.refreshCart();
      }
    },
    selectStains(row) {
      // this.index_sub_item_selected = this.index_sub_item_selected ? this.index_sub_item_selected : 0;

      if (this.index_item_selected != null && this.index_item_selected >= 0 && this.selected_items.length) {
        if (!this.selected_items[this.index_item_selected].stains) {
          this.selected_items[this.index_item_selected].stains[this.index_sub_item_selected] = [null, null, null];
        }
        let _ids = this.selected_items[this.index_item_selected].stains[this.index_sub_item_selected];
        if (_ids.includes(row.id)) {
          /// remove attached reasons
          let _index = this.selected_items[this.index_item_selected].stains[this.index_sub_item_selected].findIndex(item => item == row.id);
          this.selected_items[this.index_item_selected].stains[this.index_sub_item_selected][_index] = null;
        } else {
          /// add attached reasons
          let _index = this.selected_items[this.index_item_selected].stains[this.index_sub_item_selected].findIndex(item => item == null);
          if (_index >= 0) {
            this.selected_items[this.index_item_selected].stains[this.index_sub_item_selected][_index] = row.id;
          } else {
            this.selected_items[this.index_item_selected].stains[this.index_sub_item_selected][0] = row.id;
          }
        }
        this.refreshCart();
      }
    },
    selectBubbles(row) {
      // this.index_sub_item_selected = this.index_sub_item_selected ? this.index_sub_item_selected : 0;

      if (this.index_item_selected != null && this.index_item_selected >= 0 && this.selected_items.length) {
        if (!this.selected_items[this.index_item_selected].bubbles) {
          this.selected_items[this.index_item_selected].bubbles[this.index_sub_item_selected] = [null, null, null];
        }
        let _ids = this.selected_items[this.index_item_selected].bubbles[this.index_sub_item_selected];
        if (_ids.includes(row.id)) {
          /// remove attached reasons
          let _index = this.selected_items[this.index_item_selected].bubbles[this.index_sub_item_selected].findIndex(item => item == row.id);
          this.selected_items[this.index_item_selected].bubbles[this.index_sub_item_selected][_index] = null;
        } else {
          /// add attached reasons
          let _index = this.selected_items[this.index_item_selected].bubbles[this.index_sub_item_selected].findIndex(item => item == null);
          if (_index >= 0) {
            this.selected_items[this.index_item_selected].bubbles[this.index_sub_item_selected][_index] = row.id;
          } else {
            this.selected_items[this.index_item_selected].bubbles[this.index_sub_item_selected][0] = row.id;
          }
        }
        this.refreshCart();
      }
    },


    selectColor(row) {
      // this.index_sub_item_selected = this.index_sub_item_selected ? this.index_sub_item_selected : 0;

      if (this.index_item_selected != null && this.index_item_selected >= 0 && this.selected_items.length) {
        if (!this.selected_items[this.index_item_selected].colors) {
          this.selected_items[this.index_item_selected].colors[this.index_sub_item_selected] = [];
        }
        let _rows = this.selected_items[this.index_item_selected].colors[this.index_sub_item_selected];
        if (_rows.includes(row)) {
          /// remove color
          let _index = this.selected_items[this.index_item_selected].colors[this.index_sub_item_selected].findIndex(item => item.id == row.id);
          this.selected_items[this.index_item_selected].colors[this.index_sub_item_selected][_index] = null;
        } else {
          /// add color
          this.selected_items[this.index_item_selected].colors[this.index_sub_item_selected].push(row);
          // let _index = this.selected_items[this.index_item_selected].colors[this.index_sub_item_selected].findIndex(item => item == null);
          // if (_index >= 0) {
          //   this.selected_items[this.index_item_selected].colors[this.index_sub_item_selected][_index] = row.id;
          // } else {
          //   this.selected_items[this.index_item_selected].colors[this.index_sub_item_selected][0] = row.id;
          // }


        }
        this.refreshCart();
      }

    },

    refreshCart() {
      let _items_all = this.selected_items;
      this.selected_items = [];
      this.selected_items = _items_all;
      this.forSetColorAndReasonsActiveForCurrentItem(this.index_sub_item_selected);
      // this.$refs.items_cart.refresh();
    },
    resetSectionsColorsDamageStains() {
      this.attached_reasons_selected_for_current_item = [];
      // this.damage_selected_for_current_item = [];
      this.colors_list_selected_for_current_item = [];
      // this.stains_list_selected_for_current_item = [];
      // this.bubbles_list_selected_for_current_item = [];

      this.more_details = {
        received_date: null,
        received_time: null,
        delivery_date: null,
        delivery_time: null,
        discount_value: 0,
        discount_type: 2,
        tax: null,
        tax_id: null,
      }
    },

    /**
     * Colors And Reasons handling it as array
     * in variables to used it for checking
     */
    forSetColorAndReasonsActiveForCurrentItem(index = 0) {
      this.index_sub_item_selected = index;
      if (this.selected_items[this.index_item_selected]) {
        this.attached_reasons_selected_for_current_item = [];
        this.attached_reasons_selected_for_current_item = this.selected_items[this.index_item_selected].attached_reasons[index];

        this.colors_list_selected_for_current_item = [];
        this.colors_list_selected_for_current_item = this.selected_items[this.index_item_selected].colors[index];

        // this.damage_selected_for_current_item = [];
        // this.damage_selected_for_current_item = this.selected_items[this.index_item_selected].damages[index];
        //
        // this.stains_list_selected_for_current_item = [];
        // this.stains_list_selected_for_current_item = this.selected_items[this.index_item_selected].stains[index];
        // this.bubbles_list_selected_for_current_item = this.selected_items[this.index_item_selected].bubbles[index];
      } else {
        this.attached_reasons_selected_for_current_item = [];
        this.colors_list_selected_for_current_item = [];
        // this.damage_selected_for_current_item = [];
        // this.stains_list_selected_for_current_item = [];
        // this.bubbles_list_selected_for_current_item = [];
      }
    },
    getAttachedReasonsFromFabric(id) {
      if (!id) {
        return [];
      }
      let _obj = this.fabric_problems.find((row) => row.id == id);
      if (_obj) {
        return _obj.attached_reasons;
      } else {
        return [];
      }
    },
    /**
     * this function used to check if
     * the damage selected
     * for item selected for cart list
     * @returns {boolean}
     * @param row
     */
    isSelectedAttachedReasons(row) {
      let _status = false;
      this.attached_reasons_selected_for_current_item.forEach((_it) => {
        if (_it.attached_reasons.includes(row)) {
          _status = true;
        }
      });
      return _status;
    },
    /**
     * this function used to check if
     * the damage selected
     * for item selected for cart list
     * @param id
     * @returns {boolean}
     */
    isSelectedDamage(id) {
      return this.damage_selected_for_current_item.includes(id);
    },
    /**
     * this function used to check if
     * the stains selected
     * for item selected for cart list
     * @param id
     * @returns {boolean}
     */
    isSelectedStains(id) {
      return this.stains_list_selected_for_current_item.includes(id);
    },
    /**
     * this function used to check if
     * the bubbles selected
     * for item selected for cart list
     * @param id
     * @returns {boolean}
     */
    isSelectedBubbles(id) {
      return this.bubbles_list_selected_for_current_item.includes(id);
    },
    /**
     * this function used to check if
     * the attached reasons selected
     * for item selected for cart list
     * @param row
     * @returns {boolean}
     */
    isSelectedColor(row) {
      return this.colors_list_selected_for_current_item.includes(row);
    },

    removeAllSelectedItem() {
      if (this.is_refund_available) {
        let _item = this.selected_items[this.index_item_selected];
        if (_item.is_return) {
          this.selected_items.splice(this.index_item_selected, 1);
        }
      } else {
        this.selected_items.splice(this.index_item_selected, 1);
      }

      this.index_item_selected = null;
      if (this.selected_items.length <= 0) {
        this.total_items = 0;
        this.total_final = 0;
        this.invoice_total = 0;
        this.total_dis = 0;
        this.total_tax = 0;
      }
    },
    setMoreDetails(type) {
      if (type == 'discount') {
        this.reCalcGeneralDiscount();
      }
    },
    calcItemsSelected: _.debounce(function (){
      let _selected_items = [];
      // if (this.is_refund_available) {
      //   _selected_items = this.selected_items.filter(row => row.is_return);
      // } else {
      _selected_items = this.selected_items;
      // }

      this.total_items = this.$_.sumBy(_selected_items, function (row) {
        return ((row.unit_price ? parseFloat(row.unit_price) : 0) * (row.qty ? row.qty : 1))
      });
      this.total_items = this.total_items.toFixed(2);

      // this.total_tax = this.$_.sumBy(_selected_items, function (row) {
      //   return (row.tax_val ? parseFloat(row.tax_val) : 0);
      // });

      /**
       * calc discount dependent on type
       * @type {number|number}
       * @private
       */
      // let _sum = (this.total_items ? parseFloat(this.total_items) : 0);
      // if (this.more_details.discount_type == 3) {
      //   this.total_dis = (this.more_details.discount_value ? parseFloat(this.more_details.discount_value) : 0)
      // } else if (this.more_details.discount_type == 2) {
      //   this.total_dis = ((this.more_details.discount_value ? (parseFloat(this.more_details.discount_value) / 100) : 0) * (_sum ? _sum : 0))
      // }


      // if (this.more_details.tax) {
      //   if (this.more_details.tax.value_rate) {
      //     this.total_tax = ((this.more_details.tax.value_rate ? (parseFloat(this.more_details.tax.value_rate) / 100) : 0) * (_sum ? _sum : 0))
      //   }
      // }


      this.total_dis = this.$_.sumBy(_selected_items, function (row) {
        let _sum = ((row.unit_price ? parseFloat(row.unit_price) : 0) * (row.qty ? row.qty : 1));
        if (row.dis_val) {
          return (row.dis_val ? parseFloat(row.dis_val) : 0)
        }
        if (row.dis_per) {
          return ((row.dis_per ? (parseFloat(row.dis_per) / 100) : 0) * (_sum ? _sum : 0))
        }
      });
      this.total_dis = this.total_dis ? this.total_dis.toFixed(2) : 0;
      // if (this.is_refund_available) {
      //
      //
      //   /**
      //    * summation tax dependent on  include_tax
      //    * if be 1 used to sum
      //    * another not used
      //    */
      //   // let _total_tax_included_tax = this.$_.sumBy(_selected_items, (row) => {
      //   //   let _arr_include_taxes = row.include_taxes;
      //   //   let _include_tax = null
      //   //   if (_arr_include_taxes && _arr_include_taxes[0]) {
      //   //     _include_tax = _arr_include_taxes[0];
      //   //   }
      //   //   if (_include_tax && _include_tax.include_tax == 1) {
      //   //     return row.tax_val ? parseFloat(row.tax_val) : 0;
      //   //   }
      //   //   return 0;
      //   // });
      //   //
      //   // let _total_items = this.$_.sumBy(_selected_items, function (row) {
      //   //
      //   //   let _sum = ((row.service_price ? parseFloat(row.service_price) : 0) * (row.qty ? row.qty : 1));
      //   //   if (row.dis_val) {
      //   //     _sum = _sum - (row.dis_val ? parseFloat(row.dis_val) : 0)
      //   //   } else if (row.dis_per) {
      //   //     _sum = _sum - ((row.dis_per ? (parseFloat(row.dis_per) / 100) : 0) * (_sum ? _sum : 0))
      //   //   }
      //   //   // if (row.include_tax && row.include_tax == 1) {
      //   //   //   _sum = _sum + (row.tax_val ? parseFloat(row.tax_val) : 0);
      //   //   // }
      //   //   return _sum;
      //   // });
      //   //
      //   // this.invoice_subtotal = (((this.invoice_total ? parseFloat(this.invoice_total) : 0) - _total_items - _total_tax_included_tax)).toFixed(2);
      //   // this.total_final = (((this.total_items ? parseFloat(this.total_items) : 0) - (this.total_dis ? parseFloat(this.total_dis) : 0) + (this.shipping_val ? parseFloat(this.shipping_val) : 0) + _total_tax_included_tax)).toFixed(2);
      //
      // } else {

      /**
       * sum amount before discount
       */
      this.total_amount_before_discount = this.$_.sumBy(_selected_items, (row) => {
        return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
      });

      /**
       * sum amount after discount
       */
      this.total_amount_after_discount = this.$_.sumBy(_selected_items, (row) => {
        return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
      });
      let _amount_excluded_tax = this.$_.sumBy(_selected_items, (row) => {
        return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
      });
      this.setTotalTaxTypeVal();

      this.invoice_total_after_taxes = this.$_.sumBy(_selected_items, (row) => {
        return (row.subtotal_after_tax ? parseFloat(row.subtotal_after_tax) : 0);
      });
      this.invoice_total_before_taxes = this.$_.sumBy(_selected_items, (row) => {
        return (row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0);
      });

      this.total_tax = this.$_.sumBy(_selected_items, function (row) {
        return (row.amount_tax ? parseFloat(row.amount_tax) : 0);
      });
      this.total_tax = this.total_tax.toFixed(2);

      this.piece_no = this.$_.sumBy(_selected_items, function (row) {
        return (row.qty ? parseInt(row.qty) : 0);
      });

      // let _total_tax_included_tax = this.$_.sumBy(_selected_items, function (row) {
      //   if (row.tax.include_tax && row.tax.include_tax == 1) {
      //     let _sum_row = ((row.service_price ? parseFloat(row.service_price) : 0) * (row.qty ? row.qty : 1));
      //     return (row.tax.value_rate ? (parseFloat(row.tax.value_rate) / 100) : 0) * (_sum_row ? _sum_row : 0);
      //   }
      //   return 0;
      // });


      this.shipping_val = (this.shipping_val ? parseFloat(this.shipping_val) : 0);

      this.invoice_subtotal = (this.total_items ? parseFloat(this.total_items) : 0);
      this.total_final = (((this.total_items ? parseFloat(this.total_items) : 0) - (this.total_dis ? parseFloat(this.total_dis) : 0) + (this.shipping_val ? parseFloat(this.shipping_val) : 0) + (_amount_excluded_tax ? parseFloat(_amount_excluded_tax) : 0))).toFixed(2);
      // }
    }, 100),
    /**
     * this is function used to collection and summation every tax
     * as an object
     */
    setTotalTaxTypeVal() {
      this.total_tax_type_val = [];
      this.selected_items.forEach((row) => {
        if (row.tax_applied && row.tax_applied.length > 0) {
          row.tax_applied.forEach((_row) => {
            let index_tax = this.total_tax_type_val.findIndex(it => _row.id == it.tax_id);
            if (index_tax >= 0) {
              this.total_tax_type_val[index_tax].tax_value = this.total_tax_type_val[index_tax].tax_value + _row.tax_value;
              this.total_tax_type_val[index_tax].amount_applied = parseFloat(this.total_tax_type_val[index_tax].amount_applied) + parseFloat(row.subtotal_before_tax);
              this.total_tax_type_val[index_tax].amount_applied = this.parseToShortFloat(this.total_tax_type_val[index_tax].amount_applied);
            } else {
              this.total_tax_type_val.push({tax_id: _row.id, tax_value: _row.tax_value, amount_applied: row.subtotal_before_tax});
            }
          });

        }
        row.tax_applied
      });
    },
    indexItemSelected(index) {
      if (this.index_item_selected == index) {
        this.index_item_selected = null;
      } else {
        this.index_item_selected = index;
      }
      this.num_calc = '';

      if (this.index_item_selected != null) {
        if (!this.selected_items[this.index_item_selected].is_return && this.is_refund_available) {
          this.item_action = '';
          this.is_show_calc = false;
          this.num_calc = '';
        }
      }
      this.forSetColorAndReasonsActiveForCurrentItem();
      // this.item_action = null;
    },
    addAction(action) {
      this.item_action = action;
      this.is_show_calc = true;
      // this.num_calc = '';
      if (this.index_item_selected) {
        if (!this.selected_items[this.index_item_selected].is_return && this.is_refund_available) {
          this.item_action = '';
          this.is_show_calc = false;
          this.num_calc = '';
        }
      }
    },
    home() {
      this.num_calc = '';
      this.is_show_calc = false;
      // this.categories = this.getCategories();
      this.items = this.getItems();
      this.isClient = false;
      this.item_action = null;
      this.data.name = '';
      this.data.type = 1;
      this.invoices = [];
      this.is_refund_available = false;
    },
    selectNumber(num) {
      if (num != 'enter') {
        this.num_calc += num;
      } else {
        switch (this.item_action) {
          case 'add_qty':
            if (this.index_item_selected != null) {
              if (parseInt(this.selected_items[this.index_item_selected].available_stock_qty) >= parseInt(this.num_calc) || row.is_committed == 1) {
                this.selected_items[this.index_item_selected].qty = this.num_calc ? parseInt(this.num_calc) : 0;
                // this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].sale_price ? parseFloat(this.selected_items[this.index_item_selected].sale_price) : 0);
                // this.selected_items[this.index_item_selected].tax_val = (this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0)).toFixed(2);
              }
            }
            break;
          case 'dis_per':
            if (this.index_item_selected == null) {
              this.inner_more_details.discount_type = 2;
              this.inner_more_details.discount_value = this.num_calc;
              this.setMoreDetails('discount');
            } else {
              if (100 >= parseFloat(this.num_calc)) {
                this.selected_items[this.index_item_selected].dis_per = this.num_calc;
                this.selected_items[this.index_item_selected].dis_val = 0;
                this.selected_items[this.index_item_selected].discount_type = 2;
                this.selected_items[this.index_item_selected].discount_val = this.num_calc;

                this.selected_items[this.index_item_selected].subtotal_before_discount =  this.selected_items[this.index_item_selected].subtotal;
                this.selected_items[this.index_item_selected].subtotal_after_discount = this.selected_items[this.index_item_selected].subtotal - (this.selected_items[this.index_item_selected].subtotal * (parseFloat(this.selected_items[this.index_item_selected].discount_val) / 100));
                this.selected_items[this.index_item_selected].subtotal_before_discount = parseFloat(this.selected_items[this.index_item_selected].subtotal_before_discount).toFixed(2);
                this.selected_items[this.index_item_selected].subtotal_after_discount = parseFloat(this.selected_items[this.index_item_selected].subtotal_after_discount).toFixed(2);
              }
            }

            break;
          case 'dis_val':
            if (this.index_item_selected == null) {
              this.inner_more_details.discount_type = 3;
              this.inner_more_details.discount_value = this.num_calc;
              this.setMoreDetails('discount');
            } else {
              if (parseFloat(this.selected_items[this.index_item_selected].subtotal) >= parseFloat(this.num_calc)) {
                this.selected_items[this.index_item_selected].dis_val = this.num_calc;
                this.selected_items[this.index_item_selected].dis_per = 0;
                this.selected_items[this.index_item_selected].discount_type = 3;
                this.selected_items[this.index_item_selected].discount_val = this.num_calc;

                this.selected_items[this.index_item_selected].subtotal_before_discount =  this.selected_items[this.index_item_selected].subtotal;
                this.selected_items[this.index_item_selected].subtotal_after_discount = this.selected_items[this.index_item_selected].subtotal - parseFloat(this.selected_items[this.index_item_selected].discount_val);
                this.selected_items[this.index_item_selected].subtotal_before_discount = parseFloat(this.selected_items[this.index_item_selected].subtotal_before_discount).toFixed(2);
                this.selected_items[this.index_item_selected].subtotal_after_discount = parseFloat(this.selected_items[this.index_item_selected].subtotal_after_discount).toFixed(2);
              }
            }
            break;
          case 'price':
            if (this.index_item_selected != null) {
              this.selected_items[this.index_item_selected].sale_price = this.num_calc;
              this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].sale_price ? parseFloat(this.selected_items[this.index_item_selected].sale_price) : 0);
              this.selected_items[this.index_item_selected].tax_val = (this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0)).toFixed(2);
            }
            break;
          case 'shipping':
            this.shipping_val = this.num_calc;
            break;
        }
        /**
         * get tax for item
         */
        this.getTaxValue(this.index_item_selected);

        this.calcItemsSelected();
        this.hideCalcModal();

      }

      // if (this.item_action == 'add_qty'){
      //     this.selected_items[this.index_item_selected].qty = this.num_calc;
      // }
      // if (this.item_action == 'dis_per'){
      //     this.selected_items[this.index_item_selected].dis_per = this.num_calc;
      //     this.selected_items[this.index_item_selected].dis_val = 0;
      // }
      // if (this.item_action == 'dis_val'){
      //     this.selected_items[this.index_item_selected].dis_val = this.num_calc;
      //     this.selected_items[this.index_item_selected].dis_per = 0;
      // }

      // this.calcItemsSelected();


    },

    // selectNumber(num) {
    //   if (num != 'enter') {
    //     this.num_calc += num;
    //   } else {
    //     switch (this.item_action) {
    //       case 'add_qty':
    //         if (this.index_item_selected != null) {
    //           if (parseInt(this.selected_items[this.index_item_selected].available_stock_qty) >= parseInt(this.num_calc) || row.is_committed == 1) {
    //             this.selected_items[this.index_item_selected].qty = this.num_calc ? parseInt(this.num_calc) : 0;
    //             this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].unit_price ? parseFloat(this.selected_items[this.index_item_selected].unit_price) : 0);
    //             this.selected_items[this.index_item_selected].tax_val = (this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0)).toFixed(2);
    //           }
    //         }
    //         break;
    //       case 'dis_per':
    //         if (this.index_item_selected == null) {
    //           this.inner_more_details.discount_type = 2;
    //           this.inner_more_details.discount_value = this.num_calc;
    //           this.setMoreDetails('discount');
    //         } else {
    //           if (100 >= parseFloat(this.num_calc)) {
    //             this.selected_items[this.index_item_selected].dis_per = this.num_calc;
    //             this.selected_items[this.index_item_selected].dis_val = 0;
    //             this.selected_items[this.index_item_selected].discount_type = 2;
    //             this.selected_items[this.index_item_selected].discount_val = this.num_calc;
    //           }
    //         }
    //         break;
    //       case 'dis_val':
    //         if (this.index_item_selected == null) {
    //           this.inner_more_details.discount_type = 3;
    //           this.inner_more_details.discount_value = this.num_calc;
    //           this.setMoreDetails('discount');
    //         } else {
    //           if (parseFloat(this.selected_items[this.index_item_selected].subtotal) >= parseFloat(this.num_calc)) {
    //             this.selected_items[this.index_item_selected].dis_val = this.num_calc;
    //             this.selected_items[this.index_item_selected].dis_per = 0;
    //             this.selected_items[this.index_item_selected].discount_type = 3;
    //             this.selected_items[this.index_item_selected].discount_val = this.num_calc;
    //           }
    //         }
    //         break;
    //       case 'price':
    //         if (this.index_item_selected != null) {
    //           this.selected_items[this.index_item_selected].unit_price = this.num_calc;
    //           this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].unit_price ? parseFloat(this.selected_items[this.index_item_selected].unit_price) : 0);
    //           this.selected_items[this.index_item_selected].tax_val = (this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0)).toFixed(2);
    //         }
    //         break;
    //       case 'shipping':
    //         this.shipping_val = this.num_calc;
    //         break;
    //     }
    //     /**
    //      * get tax for item
    //      */
    //     this.getTaxValue(this.index_item_selected);
    //
    //     this.calcItemsSelected();
    //     this.hideCalcModal();
    //
    //   }
    //
    //   // if (this.item_action == 'add_qty'){
    //   //     this.selected_items[this.index_item_selected].qty = this.num_calc;
    //   // }
    //   // if (this.item_action == 'dis_per'){
    //   //     this.selected_items[this.index_item_selected].dis_per = this.num_calc;
    //   //     this.selected_items[this.index_item_selected].dis_val = 0;
    //   // }
    //   // if (this.item_action == 'dis_val'){
    //   //     this.selected_items[this.index_item_selected].dis_val = this.num_calc;
    //   //     this.selected_items[this.index_item_selected].dis_per = 0;
    //   // }
    //
    //   // this.calcItemsSelected();
    //
    //
    // },
    restCalc() {
      this.num_calc = '';
      // if (this.index_item_selected != null) {
      //   this.num_calc = '';
      //   switch (this.item_action) {
      //     case 'add_qty':
      //       this.selected_items[this.index_item_selected].qty = this.num_calc ? parseInt(this.num_calc) : 0;
      //       this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].unit_price ? parseFloat(this.selected_items[this.index_item_selected].unit_price) : 0);
      //       this.selected_items[this.index_item_selected].tax_val = (this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0)).toFixed(2);
      //       break;
      //     case 'dis_per':
      //       this.selected_items[this.index_item_selected].dis_per = this.num_calc;
      //       this.selected_items[this.index_item_selected].dis_val = 0;
      //       this.selected_items[this.index_item_selected].discount_type = 2;
      //       this.selected_items[this.index_item_selected].discount_val = this.num_calc;
      //       break;
      //     case 'dis_val':
      //       this.selected_items[this.index_item_selected].dis_val = this.num_calc;
      //       this.selected_items[this.index_item_selected].dis_per = 0;
      //       this.selected_items[this.index_item_selected].discount_type = 3;
      //       this.selected_items[this.index_item_selected].discount_val = this.num_calc;
      //       break;
      //     case 'price':
      //       this.selected_items[this.index_item_selected].unit_price = this.num_calc;
      //       this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].unit_price ? parseFloat(this.selected_items[this.index_item_selected].unit_price) : 0);
      //       this.selected_items[this.index_item_selected].tax_val = this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0);
      //       break;
      //     case 'shipping':
      //       this.shipping_val = this.num_calc;
      //       break;
      //   }
      //
      //   this.calcItemsSelected();
      // }
    },
    resetList() {
      this.selected_items = [];
      this.total_items = 0;
      this.total_final = 0;
      this.total_dis = 0;
      this.shipping_val = 0;
      this.invoice_total = 0;
      this.total_tax = 0;
      this.is_show_calc = false;
      this.isClient = false;
      this.is_refund_available = false;
      // this.getCategories();
      this.getItems();
    },
    setDiscountType(index, type) {
      this.calcDiscountForType(index, type);
      this.getTaxValue(index);
    },
    calcDiscountForType(index, type){
      this.selected_items[index].discount_type = type;
      let _ev = this.selected_items[index].discount_val;
      if (type == 2) {
        this.selected_items[index].dis_per = (_ev > 100 ? 100 : _ev);
        this.selected_items[index].discount_val = (_ev > 100 ? 100 : _ev);
        this.selected_items[index].dis_val = 0;
        this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
        this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - (parseFloat(this.selected_items[index].subtotal) * (parseFloat(this.selected_items[index].discount_val)/100));
      } else if (type == 3) {
        this.selected_items[index].dis_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
        this.selected_items[index].discount_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
        this.selected_items[index].dis_per = 0;

        this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
        this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - parseFloat(this.selected_items[index].discount_val);
      }else {
        this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
        this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal);
      }

      this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal_before_discount).toFixed(2);
      this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal_after_discount).toFixed(2);
    },
    setDiscountValPer(event, index) {
      if (event.target.value) {
        let _ev = event.target.value ? parseFloat(event.target.value) : 0;

        if (this.selected_items[index].discount_type == 2) {
          this.selected_items[index].dis_per = (_ev > 100 ? 100 : _ev);
          this.selected_items[index].discount_val = (_ev > 100 ? 100 : _ev);
          this.selected_items[index].dis_val = 0;
          this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
          this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - (parseFloat(this.selected_items[index].subtotal) * (parseFloat(this.selected_items[index].discount_val)/100));

        } else if (this.selected_items[index].discount_type == 3) {
          this.selected_items[index].dis_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
          this.selected_items[index].discount_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
          this.selected_items[index].dis_per = 0;

          this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
          this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - parseFloat(this.selected_items[index].discount_val);

        }
        this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal_before_discount).toFixed(2);
        this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal_after_discount).toFixed(2);


        setTimeout(() => {
          this.getTaxValue(index);
        }, 100)


      }

      // this.calcItemsSelected();
    },
    // setDiscountValPer(event, index) {
    //   if (event.target.value) {
    //     let _ev = event.target.value ? parseFloat(event.target.value) : 0;
    //
    //     if (this.selected_items[index].discount_type == 2) {
    //       this.selected_items[index].dis_per = (_ev > 100 ? 100 : _ev);
    //       this.selected_items[index].discount_val = (_ev > 100 ? 100 : _ev);
    //       this.selected_items[index].dis_val = 0;
    //     } else if (this.selected_items[index].discount_type == 3) {
    //       this.selected_items[index].dis_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
    //       this.selected_items[index].discount_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
    //       this.selected_items[index].dis_per = 0;
    //     }
    //     setTimeout(() => {
    //       this.getTaxValue(index);
    //     }, 100)
    //
    //
    //   }
    // },
    //recalculation discount for general
    reCalcGeneralDiscount: _.debounce(function () {

      this.is_show_validation_dis_value = false;
      let _dis_type = this.inner_more_details.discount_type;
      let _all_dis_value = this.inner_more_details.discount_value ? parseFloat(this.inner_more_details.discount_value) : 0;
      let _invoice_total = this.total_final ? parseFloat(this.total_final) : 0;
      let _total_amount_before_discount = this.total_items ? parseFloat(this.total_items) : 0;
      let _dis_value = this.inner_more_details.discount_value ? parseFloat(this.inner_more_details.discount_value) : 0;
      if (_dis_value <= 0) {
        return false;
      }
      if (_all_dis_value > _invoice_total && _dis_type == 3) {
        this.is_show_validation_dis_value = true;
        return false;
      } else if (_dis_value > 100 && _dis_type == 2) {
        this.is_show_validation_dis_value = true;
        return false;
      }
      this.selected_items = this.selected_items.map((row, index) => {
        row.subtotal_before_discount = row.subtotal;
        row.discount_type = _dis_type;
        if (_dis_type == 1) {
          row.discount_val = 0;
          row.dis_per = 0;
          row.dis_val = 0;
        } else if (_dis_type == 3) {
          let _subtotal_before_discount = row.subtotal ? parseFloat(row.subtotal) : 0;
          let _dis = parseFloat((_subtotal_before_discount / _total_amount_before_discount) * parseFloat(_dis_value)).toFixed(2);
          row.discount_val = parseFloat(_dis).toFixed(2);
          // row.discount_value = _dis_value ? parseFloat((parseFloat(_dis_value)).toFixed(2)) : 0;
          row.dis_val = row.discount_val;
          row.dis_per = 0;
          row.subtotal_after_discount = row.subtotal - parseFloat(row.discount_val);
        } else {
          row.dis_val = 0;
          row.dis_per = _dis_value;
          row.discount_val = _dis_value ? _dis_value : 0;
          row.subtotal_after_discount = row.subtotal - (row.subtotal * (parseFloat(_dis_value) / 100));
        }

        row.subtotal_before_discount = parseFloat(row.subtotal_before_discount).toFixed(2);
        row.subtotal_after_discount = parseFloat(row.subtotal_after_discount).toFixed(2);

        return row;
      });
      this.selected_items.forEach((row, index) => {
        this.getTaxValue(index)
      });
      this.calcItemsSelected();
    }, 100),

    searchOnEnter() {
      // Trigger search if the Enter key is pressed
      this.search();

    },
    showProductInfo() {
      this.show_product_info = !this.show_product_info;
    },
    selectToShowProductInfo(id) {
      this.productId = id;
      this.showProductInformationModal();
    },
    cloneSelectedItem(index) {
      if (this.is_refund_available) {
        this.index_item_selected = index;
        let _id = this.selected_items[this.index_item_selected].id;
        let _original_qty = this.selected_items[this.index_item_selected].qty;
        let _filter = this.selected_items.filter(row => (row.id == _id && row.is_return));
        if (_filter.length == 0) {
          let _clone = {...this.selected_items[this.index_item_selected]};
          _clone.original_qty = _original_qty;
          _clone.is_return = true;
          // this.selected_items.splice(this.index_item_selected + 1, 0, _clone);
          // this.selected_items[this.index_item_selected].qty = 0;
          let _service_price = (_clone.subtotal_after_discount ? (parseFloat(_clone.subtotal_after_discount) / _clone.qty) : 0);
          _clone.unit_price = _service_price;
          _clone.qty = 1;
          _clone.subtotal = _clone.qty * _service_price;
          _clone.subtotal_before_tax = _clone.qty * _service_price;

          _clone.discount_type = 1;
          _clone.discount_val = 0;
          _clone.discount_value = 0;
          _clone.dis_val = 0;
          _clone.dis_per = 0;

          this.selected_items.push(_clone);
          this.index_item_selected = null;


        }
      }
    },
    makeRefundAvailable(id) {
      this.is_refund_available = true;
      this.invoice_url = '/print/point-sale-invoice-refund';
      this.$emit('set-type-invoice', true);
      this.getItemsInvoice(id);
    },
    changeQty(index, event) {
      // if(parseInt(this.selected_items[index].available_stock_qty) >= parseInt(event)){

      if (!this.selected_items[index]) {
        return;
      }

      if (this.selected_items[index].is_return) {
        let _qty = (event ? parseInt(event) : 0);
        let _original_qty = (this.selected_items[index].original_qty ? parseInt(this.selected_items[index].original_qty) : 0);
        if (_qty > _original_qty) {
          this.selected_items[index].qty = _original_qty;
        } else {
          this.selected_items[index].qty = event;
        }
        // let _original_index =  this.selected_items.findIndex((row)=> (row.item_id == this.selected_items[index].item_id && !row.is_return));
        // this.selected_items[_original_index].qty = (this.selected_items[index].original_qty ? parseInt(this.selected_items[index].original_qty) : 0) - (this.selected_items[index].qty ? parseInt(this.selected_items[index].qty) : 0);
      } else {
        let _fabric_problems = this.getNewFabricProblemsEmpty();

        if (parseInt(this.selected_items[index].qty) < parseInt(event)) {
          this.selected_items[index].attached_reasons.push([..._fabric_problems]);
          this.selected_items[index].colors.push([]);
          this.selected_items[index].code.push('');
          this.selected_items[index].notes.push('');

        } else {
          this.selected_items[index].attached_reasons.splice(parseInt(this.selected_items[index].qty) - 1, 1);
          this.selected_items[index].colors.splice(parseInt(this.selected_items[index].qty) - 1, 1);
          this.selected_items[index].code.splice(parseInt(this.selected_items[index].qty) - 1, 1);
          this.selected_items[index].notes.splice(parseInt(this.selected_items[index].qty) - 1, 1);
        }

        this.selected_items[index].qty = (event ? parseInt(event) : 1);
      }
      this.selected_items[index].subtotal = this.parseToShortFloat((this.selected_items[index].qty ? parseInt(this.selected_items[index].qty) : 0) * (this.selected_items[index].unit_price ? parseFloat(this.selected_items[index].unit_price) : 0));

      /**
       * get tax for item
       */
      setTimeout(() => {
        if (this.is_refund_available) {
          let data = {qty: this.selected_items[index].qty, item_id: this.selected_items[index].item_id};
          this.getItemTaxForRefund(data, index)
        } else {
          this.getTaxValue(index);
        }
      }, 200);

      this.selected_items[index].subtotal_before_discount = this.parseToShortFloat(parseFloat(this.selected_items[index].subtotal));
      this.selected_items[index].subtotal_after_discount = this.parseToShortFloat(parseFloat(this.selected_items[index].subtotal) - parseFloat(this.selected_items[index].discount_val));

      this.calcItemsSelected();

    },
    removeItem(index) {

      this.selected_items.splice(index, 1);
      this.calcItemsSelected();
      this.forSetColorAndReasonsActiveForCurrentItem();
      this.active_more_details_for_item = false;
      this.index_item_selected = null;
    },

    /**
     * get tax for normal invoice
     * item_id
     * service_id
     * is_apply_tax
     * this endpoint dependent on all them to calc tax
     * @param index
     */
    async getTaxValue(index) {
      if (!this.selected_items[index]) {
        return;
      }

      let item_id = this.selected_items[index].id ? this.selected_items[index].id : null;
      let quantity = this.selected_items[index].qty ? this.selected_items[index].qty : null;
      let service_id = this.selected_items[index].service_id ? this.selected_items[index].service_id : null;
      // let unit_price = this.selected_items[index].sale_price ? this.selected_items[index].sale_price : null;
      let discount = this.selected_items[index].discount_val ? this.selected_items[index].discount_val : 0;
      let discount_type = this.selected_items[index].discount_type ? this.selected_items[index].discount_type : null;

      if (item_id && service_id && quantity)
        await ApiService.get(`/v2/items/${item_id}/services/${service_id}/calculate-subtotal`,
            {
              params: {
                row_index: index,
                quantity: quantity,
                discount: discount,
                discount_type: discount_type,
                is_apply_tax: (this.isApplyTaxesInvoice ? 1 : 0),
              }
            }).then((response) => {
          this.selected_items[response.data.data.row_index].unit_price = response.data.data.unit_price ? this.parseToShortFloat(response.data.data.unit_price) : 0;
          this.selected_items[response.data.data.row_index].amount_tax = response.data.data.tax_total ? this.parseToShortFloat(response.data.data.tax_total) : 0;
          this.selected_items[response.data.data.row_index].tax_applied = response.data.data.applied_taxes;
          this.selected_items[response.data.data.row_index].amount_excluded_tax = response.data.data.excluded_taxes_total ? this.parseToShortFloat(response.data.data.excluded_taxes_total) : 0;
          this.selected_items[response.data.data.row_index].amount_included_tax = response.data.data.included_taxes_total ? this.parseToShortFloat(response.data.data.included_taxes_total) : 0;

          this.selected_items[index].total_item_after_discount_with_excluded = this.parseToShortFloat(parseFloat(this.selected_items[response.data.data.row_index].subtotal_after_discount) + parseFloat(this.selected_items[response.data.data.row_index].amount_excluded_tax));

          let _subtotal = (response.data.data.unit_price ? parseFloat(response.data.data.unit_price) : 0) * (this.selected_items[response.data.data.row_index].qty ? this.selected_items[response.data.data.row_index].qty : 0);
          this.selected_items[response.data.data.row_index].subtotal = this.parseToShortFloat(_subtotal);
          this.selected_items[response.data.data.row_index].subtotal_before_tax = this.parseToShortFloat(_subtotal - (this.selected_items[response.data.data.row_index].amount_included_tax ? parseFloat(this.selected_items[response.data.data.row_index].amount_included_tax) : 0));
          this.selected_items[response.data.data.row_index].subtotal_after_tax = this.parseToShortFloat(_subtotal + (this.selected_items[response.data.data.row_index].amount_excluded_tax ? parseFloat(this.selected_items[response.data.data.row_index].amount_excluded_tax) : 0));

          this.calcDiscountForType(response.data.data.row_index, this.selected_items[response.data.data.row_index].discount_type);

          setTimeout(()=>{
            this.calcItemsSelected();

          }, 100);

        });

    },
    // getTaxValue(index) {
    // if (this.selected_items && this.selected_items[index]) {
    //   let item_id = this.selected_items[index].id;
    //   let item_total = this.selected_items[index].subtotal;
    //   let discount_type = this.selected_items[index].discount_type;
    //   let discount_value = this.selected_items[index].discount_val;
    //   ApiService.query(`${this.mainRouteDependency}/tax_discount_calculation`, {
    //     item_id: item_id,
    //     item_total: item_total,
    //     discount_type: discount_type,
    //     discount_value: discount_value,
    //     tax_rule_id: 1,
    //   }).then((response) => {
    //     this.selected_items[index].tax_val = response.data.data.tax_value;
    //     this.selected_items[index].include_tax = response.data.data.include_tax;
    //     this.calcItemsSelected();
    //   });
    // }
    //   this.calcItemsSelected();
    // },

    /**
     * this function used
     * invoice_id and item_id and quantity
     */
    async getItemTaxForRefund(data, index) {
      await ApiService.query(this.mainRouteDependency + "/tax_discount_calculation_refund/" + this.invoice_id, {
        ...data
      }).then((response) => {
        if (response.data.data.price_per_unit) {
          this.selected_items[index].unit_price = response.data.data.price_per_unit;
        }
        this.selected_items[index].tax_val = response.data.data.tax_value;
        this.selected_items[index].discount = response.data.data.discount;
        this.selected_items[index].include_taxes = response.data.data.include_taxes;
      });
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.user = response.data.data;
        // this.currency = response.data.data.currency_name;
        // this.currency_id = response.data.data.currency_id;
        if (this.user) {
          this.data.user_id = this.user.id;
          // this.isDisabled = false;
        }
      });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },


    getColorName(row) {
      return row.name ? row.name : '';
      // let cc = this.colors_list.find((row) => row.id == id);
      // if (cc) {
      //   return cc.name;
      // } else {
      //   return '';
      // }
    },

    getAttachedReasonsName(row) {
      return row.name ? row.name : '';
      // let cc = null;
      // let _reasons = [];
      // this.fabric_problems.forEach((row)=>{
      //   _reasons.push( ...row.attached_reasons);
      // })
      // cc = _reasons.find((row) => row.id == id);
      // if (cc) {
      //   return cc.name;
      // } else {
      //   return '';
      // }
    },

    getDamagesName(id) {
      let cc = this.damage_list.find((row) => row.id == id);
      if (cc) {
        return cc.name;
      } else {
        return '';
      }
    },

    getStainsName(id) {
      let cc = this.stains_list.find((row) => row.id == id);
      if (cc) {
        return cc.name;
      } else {
        return '';
      }
    },
    getBubblesName(id) {
      let cc = this.bubbles_list.find((row) => row.id == id);
      if (cc) {
        return cc.name;
      } else {
        return '';
      }
    },

    copyAttributes(index_item_selected, index) {
      let _ref = this.selected_items[index_item_selected].attached_reasons[index];
      let attached_reasons = [];
      _ref.forEach((row) => {
        let inner_attached_reasons = [];
        row.attached_reasons.forEach((row_2) => {
          inner_attached_reasons.push({...row_2});
        });
        row.attached_reasons = inner_attached_reasons;
        attached_reasons.push({...row})
      });
      let colors = this.selected_items[index_item_selected].colors[index];
      // let damages = this.selected_items[index_item_selected].damages[index];
      // let stains = this.selected_items[index_item_selected].stains[index];
      // let bubbles = this.selected_items[index_item_selected].bubbles[index];
      let code = this.selected_items[index_item_selected].code[index];
      let notes = this.selected_items[index_item_selected].notes[index];

      let _count = this.selected_items[index_item_selected].qty;
      for (let i = 0; i < _count; i++) {
        if (i != index) {
          this.selected_items[index_item_selected].attached_reasons[i] = [...attached_reasons];
          this.selected_items[index_item_selected].colors[i] = [...colors];
          // this.selected_items[index_item_selected].damages[i] = [...damages];
          // this.selected_items[index_item_selected].stains[i] = [...stains];
          // this.selected_items[index_item_selected].bubbles[i] = [...bubbles];
          this.selected_items[index_item_selected].code[i] = code;
          this.selected_items[index_item_selected].notes[i] = notes;

        }

      }

      this.refreshCart();
    },
    showBarCart(index, qty_index) {
      let attached_reasons = this.selected_items[index].attached_reasons[qty_index + 1];
      let colors = this.selected_items[index].colors[qty_index + 1];
      // let damages = this.selected_items[index].damages[qty_index + 1];
      // let stains = this.selected_items[index].stains[qty_index + 1];
      // let bubbles = this.selected_items[index].bubbles[qty_index + 1];
      let a_show = null;
      let c_show = null;
      // let d_show = null;
      // let s_show = null;
      if (attached_reasons) {
        a_show = attached_reasons.find((row) => row != null);
      }
      if (colors) {
        c_show = colors.find((row) => row != null);
      }
      // if (damages) {
      //   d_show = damages.find((row) => row != null);
      // }
      // if (stains) {
      //   s_show = stains.find((row) => row != null);
      // }
      // if (bubbles) {
      //   s_show = bubbles.find((row) => row != null);
      // }

      // if (a_show || c_show || d_show || s_show) {
      if (a_show || c_show) {
        return true;
      }
      return false;
    },
    // async getItemByBarcodeScanner(row) {
    //   if (row)
    //     await ApiService.get(`${this.mainRouteDependency}/item_info_by_barcode?barcode=${row}`).then((response) => {
    //       if (response.data.data.info) {
    //         this.selectItem(response.data.data.item, response.data.data.info);
    //       } else if (response.data.data) {
    //         this.selectItem(response.data.data);
    //       }
    //     });
    // },
    // onBarcodeScanned(barcode) {
    //   this.index_item_selected = null;
    //   this.data.barcode = barcode;
    //   let promise = this.getItemByBarcodeScanner(barcode);
    //   Promise.all([promise]).then(() => {
    //     this.data.barcode = '';
    //   })
    // },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    // resetBarcode() {
    //   let barcode = this.$barcodeScanner.getPreviousCode();
    //   this.data.barcode = '';
    //   // do something...
    // },
    // searchOnEnterInput(e) {
    //   if (e) {
    //     this.getItemByBarcodeScanner(e.target.value);
    //   }
    // },
    printShowingPayedInvoiceScreen() {
      this.$emit('print-showing-payed-invoice');
    },
    initialOnClickKeyboard() {
      /**
       * this listener for read event changed from keyboard
       *
       */
      let that = this;
      let _num = '';
      document.addEventListener('keyup', (ev) => {

        let _arr_ele_class = [];
        if (document.activeElement && document.activeElement.className) {
          _arr_ele_class = document.activeElement.className.split(" ");
          if (_arr_ele_class.includes('form-control'))
            return true;
        }


        if (that.index_item_selected != null && !isNaN(ev.key)) {
          _num += ev.key;
          setTimeout(() => {
            this.changeQty(that.index_item_selected, _num);
          }, 1000)
          setTimeout(() => {
            _num = '';
          }, 2000)
          ev.preventDefault();
          return false;
        }
        // else if (ev.key == 'ArrowUp' && this.index_item_selected > 0){
        //   this.index_item_selected -= 1;
        //   ev.preventDefault();
        //   return false;
        // }else if (ev.key == 'ArrowDown' && this.index_item_selected < this.selected_items.length){
        //   this.index_item_selected += 1;
        //   ev.preventDefault();
        //   return false;
        // }

      }, false);

      // document.addEventListener('keydown', function(e) {
      //
      //   // To make sure it freezes the scroll when
      //   // the first two keypresses are "ArrowUp"
      //   if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      //     e.preventDefault();
      //     return false;
      //   }
      // })
    },
    disabledOnClickKeyboard() {
      this.index_item_selected = null;
    },
    initPropsData() {
      if (this.propsData.default_payment_method) {
        this.is_cash = this.propsData.default_payment_method.id;
        this.payment_method = this.propsData.default_payment_method.id;
        this.method1_name = this.propsData.default_payment_method.title;
      }
      if (this.propsData.pos_default_customer) {
        this.switchOnProducts(this.propsData.pos_default_customer.name, this.propsData.pos_default_customer)
      }
      if (this.propsData.is_apply_taxes_setting) {
        this.is_apply_taxes_invoice = this.propsData.is_apply_taxes_setting;
      }
      if (this.propsData.payment_methods_list) {
        this.paymentMethodsList = this.propsData.payment_methods_list;
      }

    },
    initCurrency() {
      this.currency_id = this.currencyId;
      this.currency_code = this.currencyCode;
      this.currency = this.currencyCode;
    },
  },
  created() {
    this.initPropsData()
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.point_of_sales")}]);
    // this.getAuthUser();
    // this.session_id = this.sessionId;
    // this.currency_id = this.currencyId;
    // this.currency = this.innerCurrency;
    // this.currency_code = this.currencyCode;
    // this.currency = this.currencyCode;
    this.initCurrency();
    this.session_id = this.sessionId;
    this.user = this.innerUser;
    if (this.innerUser) {
      this.data.user_id = this.innerUser.id;
    }
    // this.getCategories();
    // this.$barcodeScanner.init(this.onBarcodeScanned)

    let promise_1 = this.getLaundryServices();
    Promise.all([promise_1]).then(() => {
      this.getItems();
    })
    this.getLaundryServicesColors();
    this.getFabricProblems();

    // this.getDamage();
    // this.getStains();
    // this.getBubbles();

    // this.getCustomers();
    // this.getDefaultCustomer();
    this.initialOnClickKeyboard();

    // this.invoice_url = this.is_refund_available == false ? '/print/point-sale-invoice' : '/print/point-sale-invoice-refund';

    this.date_now = this.$moment().format('DD-MM-YYYY, h:mm:ss a');
  },
}
</script>
<style>
.font-size-18 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.inline-scroll-div {
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: thin;
  -o-scrollbar-width: thin;
  -moz-scrollbar-width: thin;
  -ms-scrollbar-width: thin;
}

.category, .item {
  width: 116px;
  margin: 5px;
  height: 145px;
  text-align: center;
  /* padding: 5px; */
  box-shadow: 0 0 3px #ccc;
  background-color: #FFFFFF;
  cursor: pointer;
  overflow: hidden;
}

.attached-reason {
  border: 2px solid #b2b2b2;
  border-radius: 0;
}

.color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  border: 1px solid #000000;
}

.accordion .card .card-header {
  border-bottom: 1px solid #F3F6F9 !important;
  background-color: transparent !important;
}
</style>