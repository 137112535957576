<template>
  <div class="row mt-5" style="min-height: 515px;">
    <div class="col-md-3">
      <div class="accordion border">
        <h5 class="p-3" style="border-bottom: 1px solid #EBEDF3;"><i class="fas fa-sliders-h"></i> {{ $t('point_of_sales.filter_for_details') }} </h5>
        <div class="p-3">
          <div class="mb-4">
            <multiselect
                id="customer_id"
                v-model="customer"
                :placeholder="$t('point_of_sales.customer')"
                label="fullname"
                track-by="id"
                :options="customers"
                :multiple="false"
                :taggable="false"
                :show-labels="false"
                :show-no-options="false"
                :show-no-results="false"
                @search-change="getCustomers($event)">
            </multiselect>

          </div>

          <p>{{ $t('point_of_sales.by_date') }}</p>

          <b-form-group label="" v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="filter.sort_by" :aria-describedby="ariaDescribedby" name="sort_by" value="1">{{ $t('point_of_sales.the_most_recent') }}</b-form-radio>
            <b-form-radio v-model="filter.sort_by" :aria-describedby="ariaDescribedby" name="sort_by" value="0">{{ $t('point_of_sales.the_oldest') }}</b-form-radio>
          </b-form-group>

          <b-form-group label="" v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="filter.sort_date" :aria-describedby="ariaDescribedby" name="sort_date" value="today">{{ $t('point_of_sales.today') }}</b-form-radio>
            <b-form-radio v-model="filter.sort_date" :aria-describedby="ariaDescribedby" name="sort_date" value="last_3_days">{{ $t('point_of_sales.last_3_days') }}</b-form-radio>
            <b-form-radio v-model="filter.sort_date" :aria-describedby="ariaDescribedby" name="sort_date" value="last_month">{{ $t('point_of_sales.last_month') }}</b-form-radio>
          </b-form-group>
        </div>
        <div class="w-100 text-center mb-3">
          <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
              <span>
                <i class="fas fa-trash-restore"></i>
                <span>{{ $t('reset_search') }}</span>
              </span>
          </button>

        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="page w-100 mb-4" style="display: block;text-align: end;">
        <button  class="btn btn-warning mr-2 ml-2"  @click="searchMainList">{{$t('refresh_orders')}}</button>

        <button class="btn btn-primary btn-sm previse mr-2 ml-2" @click="previsePage()"> < </button>
        <span class="mr-2 ml-2">{{ filter.page }}</span>
        <button class="btn btn-primary btn-sm next mr-2 ml-2" @click="nextPage()"> > </button>

      </div>
      <div v-if="show_loader" class="form-group row text-center justify-content-center" style="width: 100%;height: 100%;">
        <div>
          <i class="fas fa-sync fa-spin" style="font-size: 75px"></i>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-md-4">
          <h4 class="text-center mb-5">{{ $t('point_of_sales.cleaning') }} ({{ laundries_count.cleaning }})</h4>
          <div class="col-md-12">
            <div class="card w-100 p-5 mb-4 position-relative" v-for="(cleaning, _index) in data.cleaning" :key="'cleaning'+_index">
              <a href="javascript:;" class="position-absolute" style="top: 18px;left: 14px;" @click="showItemsList(cleaning.id)">
                <i class="fa fa-eye p-0 m-0 text-0C6F58"></i>
              </a>
              <p><span><b>{{ $t('point_of_sales.customer_name') }}:</b></span> <span>{{ cleaning.customer_name }}</span></p>
              <p><span><b>{{ $t('point_of_sales.mobile') }}:</b></span> <span>{{ cleaning.customer_mobile }}</span></p>
              <p><span><b>{{ $t('point_of_sales.status') }}:</b></span> <span>{{ cleaning.progress_name }}</span> <span><i class="fa fa-dot-circle" :style="{'color': cleaning.status_color}"></i></span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_code') }}:</b></span> <span>{{ cleaning.invoice_code }}</span></p>
              <p><span><b>{{ $t('point_of_sales.quantity') }}:</b></span> <span>{{ cleaning.qty }}</span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_created_date') }}:</b></span> <span>{{ cleaning.invoice_date }}</span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_received_date') }}:</b></span> <span>{{ cleaning.received_date }}  {{ cleaning.received_time }}  </span></p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h4 class="text-center mb-5">{{ $t('point_of_sales.ready') }} ({{ laundries_count.collected }})</h4>
          <div class="col-md-12">
            <div class="card w-100 p-5 mb-4" v-for="(collected, _index) in data.collected" :key="'collected'+_index">
              <a href="javascript:;" class="position-absolute" style="top: 18px;left: 14px;" @click="showItemsList(collected.id)">
                <i class="fa fa-eye p-0 m-0 text-0C6F58"></i>
              </a>
              <p><span><b>{{ $t('point_of_sales.customer_name') }}:</b></span> <span>{{ collected.customer_name }}</span></p>
              <p><span><b>{{ $t('point_of_sales.mobile') }}:</b></span> <span>{{ collected.customer_mobile }}</span></p>
              <p><span><b>{{ $t('point_of_sales.status') }}:</b></span> <span>{{ collected.progress_name }}</span> <span><i class="fa fa-dot-circle" :style="{'color': collected.status_color}"></i></span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_code') }}:</b></span> <span>{{ collected.invoice_code }}</span></p>
              <p><span><b>{{ $t('point_of_sales.quantity') }}:</b></span> <span>{{ collected.qty }}</span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_created_date') }}:</b></span> <span>{{ collected.invoice_date }}</span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_received_date') }}:</b></span> <span>{{ collected.received_date }}  {{ collected.received_time }}  </span></p>
            </div>
          </div>

        </div>
        <div class="col-md-4">
          <h4 class="text-center mb-5">{{ $t('point_of_sales.pickups') }} ({{ laundries_count.ready }})</h4>
          <div class="col-md-12">
            <div class="card w-100 p-5 mb-4" v-for="(ready, _index) in data.ready" :key="'ready'+_index">
              <a href="javascript:;" class="position-absolute" style="top: 18px;left: 14px;" @click="showItemsList(ready.id)">
                <i class="fa fa-eye p-0 m-0 text-0C6F58"></i>
              </a>
              <p><span><b>{{ $t('point_of_sales.customer_name') }}:</b></span> <span>{{ ready.customer_name }}</span></p>
              <p><span><b>{{ $t('point_of_sales.mobile') }}:</b></span> <span>{{ ready.customer_mobile }}</span></p>
              <p><span><b>{{ $t('point_of_sales.status') }}:</b></span> <span>{{ ready.progress_name }}</span> <span><i class="fa fa-dot-circle" :style="{'color': ready.status_color}"></i></span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_code') }}:</b></span> <span>{{ ready.invoice_code }}</span></p>
              <p><span><b>{{ $t('point_of_sales.quantity') }}:</b></span> <span>{{ ready.qty }}</span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_created_date') }}:</b></span> <span>{{ ready.invoice_date }}</span></p>
              <p><span><b>{{ $t('point_of_sales.invoice_received_date') }}:</b></span> <span>{{ ready.received_date }}  {{ ready.received_time }}  </span></p>
            </div>
          </div>

        </div>
      </div>
    </div>

    <b-modal ref="orderDetailsModal" size="md" hide-footer :title="$t('details_laundry.order_details')">
      <div class="row">
        <!--        <div class="col-md-6 pt-3">-->
        <!--          <p><b>{{$t('details_laundry.construction')}}</b></p>-->
        <!--          <p v-if="order_details.invoice_code">#{{order_details.invoice_code}}</p>-->
        <!--          <p v-if="order_details.delivery_date">{{order_details.delivery_date}}</p>-->
        <!--          <p v-if="order_details.delivery_time">{{order_details.delivery_time}}</p>-->
        <!--          <p v-if="order_details.customer && order_details.customer.name">{{order_details.customer.name}}</p>-->
        <!--          <p v-if="order_details.customer && order_details.customer.address_1">{{order_details.customer.address_1}}</p>-->
        <!--          <p></p>-->
        <!--        </div>-->
        <!--        <div class="col-md-6 pt-3">-->
        <!--          <p><b>{{$t('details_laundry.payment')}}</b></p>-->
        <!--          <p v-if="order_details.is_paid_status_name">-->
        <!--            <span v-if="order_details.is_paid_status_name"> {{order_details.is_paid_status_name}}: </span>-->
        <!--            <span v-if="order_details.payment_amount"> {{order_details.payment_amount}} </span>-->
        <!--            <span v-if="order_details.currency && order_details.currency.code"> {{order_details.currency.code}} </span></p>-->
        <!--        </div>-->
        <div v-if="show_loader_modal" class="col-md-12">
          <div class="form-group row text-center justify-content-center" style="width: 100%;height: 100%;">
            <div>
              <i class="fas fa-sync fa-spin" style="font-size: 75px"></i>
            </div>
          </div>

        </div>
        <div v-else class="col-md-12 pt-3">
          <!--          <p><b>{{$t('details_laundry.cleaning')}}</b></p>-->
          <table class="table">
            <thead>
            <tr>
              <th class="text-center">{{ $t('details_laundry.item') }}</th>
              <th class="text-center">{{ $t('details_laundry.note_or_problem') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in order_details.items_list" :key="'items_list'+index">
              <td>{{ row.item.name }}</td>
              <td>
                <span v-for="(damage, damage_index) in row.damages_list" :key="'damage_index'+damage_index">{{ damage.name }} / </span>
                <span v-for="(stain, stain_index) in row.stains_list" :key="'stain_index'+stain_index">{{ stain.name }} / </span>
                <span v-for="(color, stain_index) in row.colors_list" :key="'color_index'+stain_index">{{ color.name }} / </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>

import 'vue-multiselect/dist/vue-multiselect.min.css';

import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import ApiService from "@/core/services/api.service";
import CustomerForm from "@/view/content/forms/CustomerForm";
import POSPaymentForm from "@/view/content/forms/POSLaunderyPaymentForm";
import POSRefundForm from "@/view/content/forms/POSLaunderyRefundForm";
import ProductInformationModal from "@/view/content/modals/ProductInformationModal";
import POSCleaning from "./Cleaning.vue";
import POSReady from "./Ready.vue";
import POSPickups from "./Pickups.vue";
import POSDetails from "./Details.vue";
import ItemFormLaundryModal from "@/view/content/forms/ItemFormLaundry.vue";
import {next} from "lodash/seq";

export default {
  name: "index-main-screen",
  props: {},
  data() {
    return {
      mainRoute: 'sales/sales_laundry/main_laundry',
      mainRouteDependency: 'base/dependency',
      mainRouteLaundry: 'sales/sales_laundries',
      data: {
        cleaning: [],
        ready: [],
        collected: []
      },
      laundries_count: {
        cleaning: 0,
        ready: 0,
        collected: 0
      },
      show_loader: false,
      show_loader_modal: false,
      filter: {
        page: 1,
        sort_by: 1,
        sort_date: 'today',
        customer_id: null,
      },
      customers: [],
      customer: null,
      order_details: [],
      limit: 5,
    }
  },
  watch: {
    'customer': function (val) {
      if(val){
        this.filter.page = 1;
        this.searchMainList();

      }
    },
    'filter.sort_by': function (val) {
      if (val) {
        this.filter.page = 1;
        this.searchMainList();
      }
    },
    'filter.sort_date': function (val) {
      if (val) {
        this.filter.page = 1;
        this.searchMainList();
      }
    },
    'filter.page': function (val) {
      if (val) {
        this.searchMainList();
      }
    },
  },
  methods: {
    showDetailsModal() {
      this.show_loader_modal = true;
      this.$refs['orderDetailsModal'].show();
    },
    hideDetailsModal() {
      this.order_details = null;
      this.$refs['orderDetailsModal'].hide();
    },

    async showItemsList(id) {
      this.showDetailsModal();
      await ApiService.get(`${this.mainRouteLaundry}/${id}`).then((response) => {
        this.order_details = response.data.data;
        this.show_loader_modal = false;
      });
    },
    previsePage() {
      if (this.filter.page > 1) {
        this.filter.page = this.filter.page - 1;
      }
    },
    nextPage() {
      if (this.data.cleaning.length == this.limit || this.data.ready.length == this.limit || this.data.collected.length == this.limit) {
        this.filter.page = this.filter.page + 1;
      }

    },
    showLoader() {
      this.show_loader = true;
    },
    hideLoader() {
      this.show_loader = false;
    },
    async searchMainList() {
      this.filter.customer_id = this.customer ? this.customer.id : null;
      this.showLoader();
      await ApiService.get(this.mainRoute, {
        params: {
          ...this.filter
        }
      }).then((response) => {
        this.data = response.data.data.data;
        this.laundries_count = response.data.data.laundries_count;
        this.hideLoader();
      });
    },
    getCustomers(filter) {
      if (filter && filter.length >= 3 && (filter.length != 0 || filter != null)) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    resetFilter() {
      this.filter = {
        page: 1,
        sort_by: 1,
        sort_date: 'today',
        customer_id: null,
      };
      this.customers = [];
      this.searchMainList();
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.point_of_sales")}]);
    this.searchMainList();
  },
}
</script>
<style>
.font-size-18 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.inline-scroll-div {
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: thin;
  -o-scrollbar-width: thin;
  -moz-scrollbar-width: thin;
  -ms-scrollbar-width: thin;
}

.category, .item {
  width: 116px;
  margin: 5px;
  height: 145px;
  text-align: center;
  /* padding: 5px; */
  box-shadow: 0 0 3px #ccc;
  background-color: #FFFFFF;
  cursor: pointer;
}

.attached-reason {
  border: 2px solid #b2b2b2;
  border-radius: 0;
}

.color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  border: 1px solid #000000;
}

.accordion .card .card-header {
  border-bottom: 1px solid #F3F6F9 !important;
  background-color: transparent !important;
}
</style>