<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div class="d-flex">
        <h2 class="mb-0 pt-6 pb-6">{{$t('cleaning.cleaning')}}</h2>
        <div class="input-group input-group-solid m-6 ">
          <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search"></i>
              </span>
          </div>
          <input type="text" class="form-control" v-model="filters.filter" :placeholder="$t('search')" @keydown.enter="doFilter">
        </div>

      </div>
      <div class="d-flex">
        <div class="m-5">
          <span class="d-block text-center">{{$t('orders')}}</span>
          <span class="d-block text-center">{{more_details.total_orders }}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('pieces')}}</span>
          <span class="d-block text-center">{{more_details.items_qty}}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('amount')}}</span>
          <span class="d-block text-center">{{more_details.amount}} {{currency}}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('unpaid')}}</span>
          <span class="d-block text-center">{{more_details.unpaid}} {{currency}}</span>
        </div>
        <div class="m-5 text-center">
          <button  class="btn btn-warning mr-2 ml-2"  @click="doFilter">{{$t('refresh_orders')}}</button>
          <b-button-group class="mr-2 ml-2">
            <b-button @click="ascending = (ascending==1 ? 0 : 1)" variant="primary">
              {{$t('arrange_orders')}}
              <i class="fa fa-arrow-down" v-if="ascending==1"></i>
              <i class="fa fa-arrow-up" v-else></i>
            </b-button>
            <b-dropdown :text="orderByLabel" variant="primary">
              <b-dropdown-item href="javascript:;" v-for="(or, index) in order_by_list" :key="'or-'+index" @click="getOrderBy(or.id)">{{or.label}}</b-dropdown-item>
            </b-dropdown>
          </b-button-group>

        </div>
      </div>
    </div>
    <div class="col-md-12">
      <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
        <template slot="order" slot-scope="props">
          <span v-for="(row, index) in props.row.items_list" v-if="row.item && row.item.name" class="d-block">{{ row.item.name }} <i class="fas fa-times" style="font-size: 10px;color: #3F4254;"></i> {{ row.qty }}</span>
        </template>
        <template slot="actions" slot-scope="props">
          <b-button size="sm" @click="showCleanedModal(props.row.id)">{{ $t('cleaning.cleaned') }}</b-button>
        </template>
      </v-server-table>
      <b-modal ref="cleanedModal" size="md" hide-footer :title="$t('cleaning.add_rack_location')">
        <div class="row">
          <div class="col-md-12 pt-3">
            <div>
              <div class="row mb-5">
                <div class="col-md-6"><b>{{ $t('cleaning.rack') }}</b></div>
                <div class="col-md-6"><input type="text" class="form-control" v-model="rack"></div>
              </div>
              <div class="row mt-10">
                <div class="col-md-12 text-center">
                  <b-button variant="primary" @click="saveCleaned">{{ $t('cleaning.confirm') }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Cleaning",
  props:{
    currency: {type: String}
  },
  data() {
    return {
      mainRoute: 'sales/sales_laundry/service_cleaning',
      mainCleanedRoute: 'sales/sales_laundry/service_cleaned',
      data: [],
      columns: ['id', 'invoice_code', 'invoice_date', 'customer_name', 'order', 'invoice_total', 'actions'],
      invoice_id: null,
      rack: null,
      validation: null,
      filters:{
        filter:''
      },
      more_details: {
        total_orders: 0,
        items_qty: 0,
        amount: 0,
        unpaid: 0,
      },
      order_by_list:[
        {id: 'id', label:this.$t('cleaning.id')},
        {id: 'invoice_code', label:this.$t('cleaning.invoice_code')},
        {id: 'invoice_date', label:this.$t('cleaning.invoice_date')},
        {id: 'invoice_total', label:this.$t('cleaning.total')},
      ],
      ascending: 1,
      orderBy:'id',
    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('cleaning.id'),
          invoice_code: that.$t('cleaning.invoice_code'),
          invoice_date: that.$t('cleaning.invoice_date'),
          customer_name: that.$t('cleaning.customer'),
          order: that.$t('cleaning.order'),
          pcs: that.$t('cleaning.Pcs'),
          notes: that.$t('cleaning.notes'),
          invoice_total: that.$t('cleaning.total'),
          actions: '',
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [],

        requestFunction(data) {
          let _params = {
            ascending: that.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: that.orderBy,
            page: data.page,
            // filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          that.more_details.total_orders = resp.data.data.more_details.total_orders;
          that.more_details.items_qty = resp.data.data.more_details.items_qty;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
    orderByLabel(){
      let _label = this.order_by_list.find(row=>row.id == this.orderBy);
      return _label? _label.label : '';

    }
  },
  watch:{
    ascending: function (val){
      this.doFilter();
    }
  },
  methods: {
    showCleanedModal(id) {
      this.invoice_id = id;
      this.$refs['cleanedModal'].show();
    },
    hideCleanedModal() {
      this.invoice_id = null;
      this.rack = null;
      this.$refs['cleanedModal'].hide();
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    saveCleaned() {
      if (this.invoice_id)
        ApiService.patch(this.mainCleanedRoute + '/' + this.invoice_id, {
          rack: this.rack,
        }).then((response) => {
          this.$successAlert(response.data.message);
          this.doFilter();
          this.hideCleanedModal();
        }).catch((error) => {
          this.$errorAlert(error);
          this.validation = error.response ? error.response.data.errors : null;
        });
    },
    getOrderBy(id){
      this.orderBy = id;
    },
  }
}
</script>

<style scoped>

</style>