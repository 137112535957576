<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div class="d-flex">
        <h2 class="mb-0 pt-6 pb-6">{{$t('pickups.pickups')}}</h2>
        <div class="input-group input-group-solid m-6 ">
          <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search"></i>
              </span>
          </div>
          <input type="text" class="form-control" v-model="filters.filter" :placeholder="$t('search')" @keydown.enter="doFilter">
        </div>

      </div>
      <div class="d-flex">
        <div class="m-5">
          <span class="d-block text-center">{{$t('orders')}}</span>
          <span class="d-block text-center">{{more_details.total_orders }}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('pieces')}}</span>
          <span class="d-block text-center">{{more_details.items_qty}}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('amount')}}</span>
          <span class="d-block text-center">{{more_details.amount}} {{currency}}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('unpaid')}}</span>
          <span class="d-block text-center">{{more_details.unpaid}} {{currency}}</span>
        </div>
        <div class="m-5 text-center">
          <button  class="btn btn-warning mr-2 ml-2"  @click="doFilter">{{$t('refresh_orders')}}</button>
          <b-button-group class="mr-2 ml-2">
            <b-button @click="ascending = (ascending==1 ? 0 : 1)" variant="primary">
              {{$t('arrange_orders')}}
              <i class="fa fa-arrow-down" v-if="ascending==1"></i>
              <i class="fa fa-arrow-up" v-else></i>
            </b-button>
            <b-dropdown :text="orderByLabel" variant="primary">
              <b-dropdown-item href="javascript:;" v-for="(or, index) in order_by_list" :key="'or-'+index" @click="getOrderBy(or.id)">{{or.label}}</b-dropdown-item>
            </b-dropdown>
          </b-button-group>

        </div>
      </div>
    </div>
    <div class="col-md-12">
      <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
        <template slot="order" slot-scope="props">
          <span v-for="(row, index) in props.row.items_list" v-if="row.item && row.item.name" class="d-block">{{ row.item.name }} <i class="fas fa-times" style="font-size: 10px;color: #3F4254;"></i> {{ row.qty }}</span>
        </template>
        <template slot="address_1" slot-scope="props">
          <div v-if="props.row.customer">
            <span class="d-block mb-2" v-if="props.row.customer.address_1">{{props.row.customer.address_1}}</span>
            <span class="d-block mb-2" v-if="props.row.customer.mobile">{{props.row.customer.mobile}}</span>
            <span class="d-block mb-2" v-if="props.row.customer.email">>{{props.row.customer.email}}</span>
          </div>
        </template>
        <template slot="actions" slot-scope="props">
          <b-button size="sm" class="ml-2 mr-2" @click="showChangeUrgentOrderTimeModal(props.row.id)">{{ $t('add_urgent_order_time') }}</b-button>

          <a  :href="`/print/pos-laundry-sales-80mm-invoice/${props.row.id}/print`" target="_blank"  class="btn btn-light ml-3 mr-3" v-b-tooltip.hover :title="$t('print')">
            <i class="fas fa-print"></i>
          </a>
        </template>

      </v-server-table>

      <b-modal ref="changeUrgentOrderTimeModal" size="md" hide-footer :title="$t('add_urgent_order_time')">
        <div class="row">
          <div class="col-md-12 mb-5 text-center">
            <b-time v-model="urgent_order_time" block v-bind="labels[LANG] || {}" :locale="LANG"></b-time>

            <h3 class="text-center mt-10">{{urgent_order_time}}</h3>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <b-button class="mt-2 mr-1" variant="secondary" @click="hideChangeUrgentOrderTimeModal">{{ $t('close') }}</b-button>
          <b-button class="mt-2 mr-1" variant="primary" @click="saveUrgentOrderTimeDate">{{ $t('save') }}</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Pickups",
  components: {},
  props:{
    currency: {type: String}
  },
  data() {
    return {
      mainRoute: 'sales/sales_laundry/service_pickups',
      mainChangeUrgentOrderTimeRoute: 'pos/point_sales_laundry/urgent_order',
      data: [],
      columns: ['id', 'invoice_code', 'invoice_date', 'customer_name', 'address_1', 'order', 'rack', 'invoice_total', 'actions'],
      invoice_id: null,
      validation: null,

      selected_items: [],
      total_dis: 0,
      total_items: 0,
      shipping_val: 0,
      customer: [],
      // currency_id: null,
      // currency: null,
      // currency_name: null,
      total_tax: 0,
      total_final: 0,
      filters:{
        filter:''
      },
      more_details: {
        total_orders: 0,
        items_qty: 0,
        amount: 0,
        unpaid: 0,
      },
      order_by_list:[
        {id: 'id', label:this.$t('cleaning.id')},
        {id: 'invoice_code', label:this.$t('cleaning.invoice_code')},
        {id: 'invoice_date', label:this.$t('cleaning.invoice_date')},
        {id: 'invoice_total', label:this.$t('cleaning.total')},
      ],
      ascending: 1,
      orderBy:'id',
      LANG: localStorage.getItem("language") || 'ar',
      labels: {
        'ar': {
          labelHours: 'ساعات',
          labelMinutes: 'الدقائق',
          labelSeconds: 'ثواني',
          labelAmpm: 'صباحا مساء',
          labelAm: 'ص',
          labelPm: 'م',
          labelIncrement: 'زيادة',
          labelDecrement: 'إنقاص',
          labelSelected: 'الوقت المحدد',
          labelNoTimeSelected: 'لا وقت المختار'
        },
      },
      urgent_order_time: null,
    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('ready.id'),
          invoice_code: that.$t('pickups.invoice_code'),
          invoice_date: that.$t('pickups.invoice_date'),
          customer_name: that.$t('pickups.customer'),
          address_1: that.$t('pickups.address'),
          order: that.$t('pickups.order'),
          rack: that.$t('pickups.rack'),
          invoice_total: that.$t('pickups.total'),
          actions: '',
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [],

        requestFunction(data) {
          let _params = {
            ascending: that.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: that.orderBy,
            page: data.page,
            // filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          that.more_details.total_orders = resp.data.data.more_details.total_orders;
          that.more_details.items_qty = resp.data.data.more_details.items_qty;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
    orderByLabel(){
      let _label = this.order_by_list.find(row=>row.id == this.orderBy);
      return _label? _label.label : '';

    }
  },
  watch:{
    ascending: function (val){
      this.doFilter();
    }
  },
  methods: {
    doFilter() {
      this.$refs.table.refresh();
    },
    getOrderBy(id){
      this.orderBy = id;
    },
    showChangeUrgentOrderTimeModal(id) {
      this.invoice_id = id;
      this.$refs['changeUrgentOrderTimeModal'].show();
    },
    hideChangeUrgentOrderTimeModal() {
      this.invoice_id = null;
      this.urgent_order_time = null;
      this.$refs['changeUrgentOrderTimeModal'].hide();
    },
    saveUrgentOrderTimeDate() {
      if (this.invoice_id)
        ApiService.patch(this.mainChangeUrgentOrderTimeRoute + '/' + this.invoice_id,{
          urgent_order_time: this.urgent_order_time,
        }).then((response) => {
          this.$successAlert(response.data.message);
          this.doFilter();
          this.hideChangeUrgentOrderTimeModal();
        }).catch((error) => {
          this.$errorAlert(error);
          this.validation = error.response ? error.response.data.errors : null;
        });
    },
  }
}
</script>

<style scoped>

</style>