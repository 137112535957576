<template>
  <div>
    <!--begin::customer-->

    <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.name') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.name" class="form-control"
                     :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('items.name')"/>
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.name[0] }}
                                    </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.sku_code') }}</label>
              <input type="text" v-model="data.sku_code" class="form-control"
                     :class="validation && validation.sku_code ? 'is-invalid' : ''"
                     :placeholder="$t('items.sku_code')"/>
              <span v-if="validation && validation.sku_code" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.sku_code[0] }}
                                    </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.barcode') }}</label>
              <input type="text" v-model="data.barcode" class="form-control"
                     :class="validation && validation.barcode ? 'is-invalid' : ''" :placeholder="$t('items.barcode')"/>
              <span v-if="validation && validation.barcode" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.barcode[0] }}
                                    </span>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('items.category') }}<span class="text-danger">*</span></label>
              <treeselect
                  :options="categories"
                  :load-options="loadOptions"
                  :multiple="false"
                  :value="data.category_id"
                  @input="updateValue"
                  :searchable="true"
                  :show-count="true"
                  :no-children-text="$t('No_sub_options')"
                  :no-options-text="$t('No_options_available')"
                  :no-results-text="$t('No_results_found')"
                  :placeholder="$t('Select')">
              </treeselect>
              <span v-if="validation && validation.category_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.category_id[0] }}
                                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.description') }}</label>
              <textarea type="text" v-model="data.description" class="form-control"
                        :class="validation && validation.description ? 'is-invalid' : ''"
                        :placeholder="$t('items.description')"></textarea>
              <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.description[0] }}
                                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}
              </b-form-checkbox>
            </div>

          </div>
    <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-end mt-2 p-2 bg-FFB803" style="height: 45px;">
                <button type="button" class="btn btn-primary btn-sm" @click="addServices">{{ $t('add_more') }}</button>
              </div>
            </div>

            <div class="col-md-12">
              <div class="bg-white">
                <table class="table table-row-bordered table-custom-padding" @keyup.enter="addServices" @keyup.46="removeRowServices(0)">
                  <thead>
                  <tr>
                    <th style="width:25%">{{ $t('laundry_services_pricing.service_name') }}</th>
                    <th style="width:10%">{{ $t('laundry_services_pricing.price') }}</th>
                    <!--                            <th style="width:10%">{{ $t('laundry_services_pricing.number_of_pieces') }}</th>-->
                    <th style="width:15%">{{ $t('laundry_services_pricing.tax') }}</th>
                    <th style="width:15%">{{ $t('laundry_services_pricing.status') }}</th>
                    <th style="width:15%"></th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in services" :key="index">
                    <td>
                      <template v-if="!row.category_id">
                        <multiselect v-model="row.laundry_service" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('laundry_services_pricing.service_name')"
                                     :placeholder="$t('laundry_services_pricing.service_name')"
                                     label="name"
                                     track-by="id"
                                     :options="laundry_services"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false">
                        </multiselect>
                        <span v-if="validation && validation['laundry.'+index+'.laundry_service']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation['laundry.'+index + '.laundry_service'][0] }}
                                    </span>

                      </template>
                      <span v-else>{{row.laundry_service ? row.laundry_service.name : ''}}</span>
                    </td>
                    <td>
                      <input type="number" step="1.0" v-model="row.price" class="form-control" :class="validation && validation[index+'.price'] ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation['laundry.'+index+'.price']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation['laundry.'+index + '.price'][0] }}
                                    </span>
                    </td>
                    <!--                            <td>-->
                    <!--                              <input type="number" step="1.0" v-model="row.qty" class="form-control" :class="validation && validation[index+'.qty'] ? 'is-invalid' : ''"/>-->
                    <!--                              <span v-if="validation && validation[index+'.qty']" class="fv-plugins-message-container invalid-feedback">-->
                    <!--                                      {{ validation[index + '.qty'][0] }}-->
                    <!--                                    </span>-->
                    <!--                            </td>-->
                    <td>
                      <template v-if="!row.category_id">
                        <multiselect v-model="row.tax" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.tax')"
                                     :placeholder="$t('sales_invoices.tax')"
                                     label="name"
                                     track-by="id"
                                     :options="taxes"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false"
                                     @search-change="getTaxesFilter($event)">
                        </multiselect>
                        <span v-if="validation && validation['laundry.'+index+'.tax']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation['laundry.'+index + '.tax'][0] }}
                                    </span>
                      </template>
                      <span v-else>{{row.tax ? row.tax.name : ''}}</span>
                    </td>


                    <td>
                      <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
                    </td>
                    <td>
                      <v-icon v-if="!row.category_id && services.length > 1" style="color: #dc3545;" small @click="removeRowServices(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>

          </div>

    <div class="card-footer pl-0 pr-0">
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>

    <!--end::customer-->
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "item-form-laundry-modal",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    isManufacture: {type: Boolean, default: false},
  },
  data() {
    return {
      mainRoute: 'pos/point_sales_laundry/add/item',
      mainRouteDependency: 'base/dependency',
      mainRouteLaundryServices: 'settings/categories_list/laundry_services',

      categories: [],
      taxes: [],


      data: {
        name: null,
        category_id: null,
        description: null,
        special_description: null,
        sku_code: null,
        barcode: null,
        is_active: true,
        item_type: 1,

      },
      validation: null,

      /**
       * laundry service data
       */
      service_from: {id: null, laundry_service: null, price: null, qty: 1, is_active: null, is_manually_added: true, category_id: null, tax: []},
      services: [],
      laundry_services: [],
    };
  },

  methods: {
    save() {
      this.create();
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    create() {
      ApiService.patch(`${this.mainRoute}`, {
        laundry: this.services,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.finishedSave(response.data.data);
        this.cancel();
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    updateValue(value) {
      this.data.category_id = value;
      this.getLaundryServicesForCategory(value);
    },
    loadOptions() {
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },


    resetAfterSave() {
      this.data.name = null;
      this.data.category_id = null;
      this.data.description = null;
      this.data.special_description = null;
      this.data.sku_code = null;
      this.data.barcode = null;
      this.data.image = null;
      this.data.is_active = true;
      this.services = [];
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/9").then((response) => {
        this.data.sku_code = response.data.data.code;
      });
    },
    /**
     *
     * Laundry Services functions
     */

    addServices() {
      this.services.unshift(this.service_from);
      this.service_from = {id: null, laundry_service: null, price: null, qty: 1, is_active: null, is_manually_added: true, category_id: null, tax: []};
    },

    removeRowServices(index) {
      this.services.splice(index, 1);
    },
    getTaxesFilter(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },
    getLaundryServices() {
      ApiService.get(`${this.mainRouteDependency}/laundry_services`).then((response) => {
        this.laundry_services = response.data.data;
      });
    },
    async getLaundryServicesForCategory(category_id) {
      if (category_id)
        ApiService.get(`${this.mainRouteLaundryServices}/list/${category_id}`).then((response) => {
          if (response.data.data && response.data.data.length > 0){
            this.services = response.data.data ? response.data.data.map((row)=>{
              return {
                id: null,
                laundry_service: row.laundry_service,
                price: row.price,
                qty: row.qty,
                tax: row.tax,
                is_active: row.is_active,
                is_manually_added: false,
                category_id: row.category_id? row.category_id : null
              }
            }) : [];
          }
        });
    },
  },

  mounted() {
    this.getCategories();
    this.getCode();
    this.getLaundryServices();
    this.addServices();
  },
};
</script>