<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div class="d-flex">
        <h2 class="mb-0 pt-6 pb-6">{{$t('ready.ready')}}</h2>
        <div class="input-group input-group-solid m-6 ">
          <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search"></i>
              </span>
          </div>
          <input type="text" class="form-control" v-model="filters.filter" :placeholder="$t('search')" @keydown.enter="doFilter">
        </div>

      </div>
      <div class="d-flex">
        <div class="m-5">
          <span class="d-block text-center">{{$t('orders')}}</span>
          <span class="d-block text-center">{{more_details.total_orders }}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('pieces')}}</span>
          <span class="d-block text-center">{{more_details.items_qty}}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('amount')}}</span>
          <span class="d-block text-center">{{more_details.amount}} {{currency}}</span>
        </div>
        <div class="m-5">
          <span class="d-block text-center">{{$t('unpaid')}}</span>
          <span class="d-block text-center">{{more_details.unpaid}} {{currency}}</span>
        </div>
        <div class="m-5 text-center">
          <button  class="btn btn-warning mr-2 ml-2"  @click="doFilter">{{$t('refresh_orders')}}</button>
          <b-button-group class="mr-2 ml-2">
            <b-button @click="ascending = (ascending==1 ? 0 : 1)" variant="primary">
              {{$t('arrange_orders')}}
              <i class="fa fa-arrow-down" v-if="ascending==1"></i>
              <i class="fa fa-arrow-up" v-else></i>
            </b-button>
            <b-dropdown :text="orderByLabel" variant="primary">
              <b-dropdown-item href="javascript:;" v-for="(or, index) in order_by_list" :key="'or-'+index" @click="getOrderBy(or.id)">{{or.label}}</b-dropdown-item>
            </b-dropdown>
          </b-button-group>

        </div>
      </div>
    </div>
    <div class="col-md-12">
      <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
        <template slot="order" slot-scope="props">
          <span v-for="(row, index) in props.row.items_list" v-if="row.item && row.item.name" class="d-block">{{ row.item.name }} <i class="fas fa-times" style="font-size: 10px;color: #3F4254;"></i> {{ row.qty }}</span>
        </template>
        <template slot="delivery_date" slot-scope="props">
          <span class="d-block">{{ props.row.delivery_date }} </span>
          <span class="d-block">{{ props.row.delivery_time }} </span>
        </template>
        <template slot="actions" slot-scope="props">
<!--          <b-button v-if="props.row.extra_paid_info && props.row.extra_paid_info.sub_payment_method == 1" size="sm" @click="showPaymentModal(props.row)">{{ $t('ready.payment') }}</b-button>-->
          <b-button v-if="!props.row.is_collected" size="sm" @click="showCollectedModal(props.row.id)">{{ $t('ready.collected') }}</b-button>
          <b-button size="sm" class="ml-2 mr-2" @click="showChangeDeliveryDateModal(props.row.id)">{{ $t('change_the_delivery_date') }}</b-button>
          <b-button size="sm" class="ml-2 mr-2" @click="showCompletedOrderModal(props.row.id)">{{ $t('ready.completed_order') }}</b-button>
          <b-button size="sm" class="ml-2 mr-2" @click="showChangeRackLocationModal(props.row.id)">{{ $t('ready.change_rack_location') }}</b-button>
        </template>
      </v-server-table>
      <b-modal ref="collectedModal" size="md" hide-footer :title="''">
        <div class="row">
          <div class="col-md-12 pt-3 pb-3 text-center">
            <h5 class="mb-4">{{$t('ready.are_you_sure_make_it_collected')}}</h5>
            <b-button variant="primary" @click="saveCollected">{{ $t('ready.confirm') }}</b-button>
          </div>
        </div>
      </b-modal>
      <b-modal ref="changeDeliveryDateModal" size="lg" hide-footer :title="$t('change_the_delivery_date')">
        <div class="row">
          <div class="col-md-8 mb-5">

            <b-calendar v-model="delivery_date" block class="border rounded p-2" :locale="LANG"></b-calendar>
          </div>
          <div class="col-md-4 mb-5">
            <b-time v-model="delivery_time" block v-bind="labels[LANG] || {}" :locale="LANG"></b-time>

            <h3 class="text-center mt-10">{{delivery_date}}  {{delivery_time}}</h3>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <b-button class="mt-2 mr-1" variant="secondary" @click="hideChangeDeliveryDateModal">{{ $t('close') }}</b-button>
          <b-button class="mt-2 mr-1" variant="primary" @click="saveChangeDeliveryDate">{{ $t('save') }}</b-button>
        </div>
      </b-modal>
      <b-modal ref="changeRackLocationModal" size="md" hide-footer :title="$t('ready.change_rack_location')">
        <div class="row">
          <div class="col-md-12 pt-3">
            <div>
              <div class="row mb-5">
                <div class="col-md-6"><b>{{ $t('ready.rack') }}</b></div>
                <div class="col-md-6"><input type="text" class="form-control" v-model="rack"></div>
              </div>
              <div class="row mt-10">
                <div class="col-md-12 text-center">
                  <b-button variant="primary" @click="saveChangeRackLocation">{{ $t('ready.confirm') }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal ref="changeRackLocationModal" size="md" hide-footer :title="$t('ready.change_rack_location')">
        <div class="row">
          <div class="col-md-12 pt-3">
            <div>
              <div class="row mb-5">
                <div class="col-md-6"><b>{{ $t('ready.rack') }}</b></div>
                <div class="col-md-6"><input type="text" class="form-control" v-model="rack"></div>
              </div>
              <div class="row mt-10">
                <div class="col-md-12 text-center">
                  <b-button variant="primary" @click="saveChangeRackLocation">{{ $t('ready.confirm') }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal ref="completedModal" size="md" hide-footer :title="$t('ready.order_status')">
        <div class="row">
          <div class="col-md-12 pt-3 pb-3 text-center">
            <h5 class="mb-4">{{$t('ready.are_you_sure_make_it_completed')}}</h5>
            <b-button variant="primary" @click="saveCompleted">{{ $t('ready.confirm') }}</b-button>
          </div>
        </div>
      </b-modal>
<!--      <b-modal ref="paymentModal" size="xl" hide-footer :title="$t('point_of_sales.payment')">-->
<!--        <pos-payment-form-->
<!--                          @hide-modal="hidePaymentModal()"-->
<!--                          @hide-save-modal="hidePrintPaymentModal()"-->
<!--                          @reset-list="doFilter()"-->
<!--                          :items="selected_items"-->
<!--                          :total-dis="total_dis"-->
<!--                          :total-items="total_items"-->
<!--                          :shipping-val="shipping_val"-->
<!--                          :customer="customer"-->
<!--                          :currency-id="currency_id"-->
<!--                          :currency-name="currency_name"-->
<!--                          :total-tax="total_tax"-->
<!--                          :total-final="total_final"-->
<!--                          :more-details="more_details"-->
<!--        ></pos-payment-form>-->
<!--      </b-modal>-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import POSPaymentForm from "@/view/content/forms/POSLaunderyPaymentForm";

export default {
  name: "Ready",
  components: {'pos-payment-form': POSPaymentForm},
  props:{
    currency: {type: String}
  },
  data() {
    return {
      LANG: localStorage.getItem("language") || 'ar',
      mainRoute: 'sales/sales_laundry/service_ready',
      mainCollectedRoute: 'sales/sales_laundry/service_collected',
      mainChangeDeliveryDateRoute: 'sales/sales_laundry/change_delivery_date',
      mainChangeRackRoute: 'sales/sales_laundry/service_cleaned',
      mainCompletedRoute: 'pos/point_sales_laundry/complete_order',
      data: [],
      columns: ['id', 'invoice_code', 'invoice_date', 'customer_name', 'order', 'rack', 'delivery_date',  'invoice_total', 'actions'],
      invoice_id: null,
      validation: null,

      selected_items: [],
      total_dis: 0,
      total_items: 0,
      shipping_val: 0,
      customer: [],
      // currency_id: null,
      // currency: null,
      // currency_name: null,
      total_tax: 0,
      total_final: 0,
      filters:{
        filter:''
      },
      more_details: {
        total_orders: 0,
        items_qty: 0,
        amount: 0,
        unpaid: 0,
      },
      delivery_date: null,
      delivery_time: null,


      labels: {
        'ar': {
          labelHours: 'ساعات',
          labelMinutes: 'الدقائق',
          labelSeconds: 'ثواني',
          labelAmpm: 'صباحا مساء',
          labelAm: 'ص',
          labelPm: 'م',
          labelIncrement: 'زيادة',
          labelDecrement: 'إنقاص',
          labelSelected: 'الوقت المحدد',
          labelNoTimeSelected: 'لا وقت المختار'
        },
      },
      order_by_list:[
        {id: 'id', label:this.$t('cleaning.id')},
        {id: 'invoice_code', label:this.$t('cleaning.invoice_code')},
        {id: 'invoice_date', label:this.$t('cleaning.invoice_date')},
        {id: 'invoice_total', label:this.$t('cleaning.total')},
      ],
      ascending: 1,
      orderBy:'id',
      rack: null,


    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('ready.id'),
          invoice_code: that.$t('ready.invoice_code'),
          invoice_date: that.$t('ready.invoice_date'),
          customer_name: that.$t('ready.customer'),
          order: that.$t('ready.order'),
          pcs: that.$t('ready.Pcs'),
          notes: that.$t('ready.notes'),
          rack: that.$t('ready.rack'),
          delivery_date: that.$t('delivery_date'),
          invoice_total: that.$t('ready.total'),
          actions: '',
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [],

        requestFunction(data) {
          let _params = {
            ascending: that.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: that.orderBy,
            page: data.page,
            // filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          that.more_details.total_orders = resp.data.data.more_details.total_orders;
          that.more_details.items_qty = resp.data.data.more_details.items_qty;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
    orderByLabel(){
      let _label = this.order_by_list.find(row=>row.id == this.orderBy);
      return _label? _label.label : '';

    }
  },
  watch:{
    ascending: function (val){
      this.doFilter();
    }
  },
  methods: {
    showCollectedModal(id) {
      this.invoice_id = id;
      this.$refs['collectedModal'].show();
    },
    hideCollectedModal() {
      this.invoice_id = null;
      this.$refs['collectedModal'].hide();
    },
    showCompletedOrderModal(id) {
      this.invoice_id = id;
      this.$refs['completedModal'].show();
    },
    hideCompletedOrderModal() {
      this.invoice_id = null;
      this.$refs['completedModal'].hide();
    },
    showChangeDeliveryDateModal(id) {
      this.invoice_id = id;
      this.$refs['changeDeliveryDateModal'].show();
    },
    hideChangeDeliveryDateModal() {
      this.invoice_id = null;
      this.$refs['changeDeliveryDateModal'].hide();
    },
    showChangeRackLocationModal(id) {
      this.invoice_id = id;
      this.$refs['changeRackLocationModal'].show();
    },
    hideChangeRackLocationModal() {
      this.invoice_id = null;
      this.rack = null;
      this.$refs['changeRackLocationModal'].hide();
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    saveCollected() {
      if (this.invoice_id)
        ApiService.patch(this.mainCollectedRoute + '/' + this.invoice_id,{is_collected: 1}).then((response) => {
          this.$successAlert(response.data.message);
          this.doFilter();
          this.hideCompletedOrderModal();
        }).catch((error) => {
          this.$errorAlert(error);
          this.validation = error.response ? error.response.data.errors : null;
        });
    },
    saveCompleted() {
      if (this.invoice_id)
        ApiService.patch(this.mainCompletedRoute + '/' + this.invoice_id,{is_completed: 1}).then((response) => {
          this.$successAlert(response.data.message);
          this.doFilter();
          this.hideCompletedOrderModal();
        }).catch((error) => {
          this.$errorAlert(error);
          this.validation = error.response ? error.response.data.errors : null;
        });
    },
    saveChangeDeliveryDate() {
      if (this.invoice_id)
        ApiService.patch(this.mainChangeDeliveryDateRoute + '/' + this.invoice_id,{
          delivery_date: this.delivery_date,
          delivery_time: this.delivery_time,
        }).then((response) => {
          this.$successAlert(response.data.message);
          this.doFilter();
          this.hideChangeDeliveryDateModal();
        }).catch((error) => {
          this.$errorAlert(error);
          this.validation = error.response ? error.response.data.errors : null;
        });
    },
    getOrderBy(id){
      this.orderBy = id;
    },
    saveChangeRackLocation() {
      if (this.invoice_id)
        ApiService.patch(this.mainChangeRackRoute + '/' + this.invoice_id, {
          rack: this.rack,
        }).then((response) => {
          this.$successAlert(response.data.message);
          this.doFilter();
          this.hideChangeRackLocationModal();
        }).catch((error) => {
          this.$errorAlert(error);
          this.validation = error.response ? error.response.data.errors : null;
        });
    },
    // showPaymentModal(row) {
    //   this.selected_items = row.items_list;
    //   this.total_dis = row.invoice_total_discount;
    //   this.total_items = row.invoice_subtotal;
    //   this.shipping_val = row.invoice_total_shipping;
    //   this.customer = row.customer;
    //   this.currency_id = row.currency_id;
    //   this.currency = row.currency;
    //   this.currency_name = row.currency_name;
    //   this.total_tax = row.invoice_total_taxes;
    //   this.total_final = row.invoice_total;
    //   this.$refs['paymentModal'].show();
    // },
    // hidePaymentModal() {
    //   this.$refs['paymentModal'].hide();
    // },
    // hidePrintPaymentModal() {
    //   this.$emit('handling-data', 'payment');
    //   this.$refs['paymentModal'].hide();
    // },

  }
}
</script>

<style scoped>

</style>